import _ from 'lodash';

class selectionMode {
    constructor(arrayTobeUpdate) {
        this.selectedArray = _.cloneDeep(arrayTobeUpdate);
    }
  
    updateSelectedArray(new_obj, photo_obj, workId) {
        if(!this.selectedArray[workId]){
            this.selectedArray[workId] = _.cloneDeep(new_obj[workId]);  
        }
        if (this.selectedArray[workId].some((e)=> e.photo_id == photo_obj.photo_id)) {
            this.selectedArray[workId] = this.selectedArray[workId].filter((item) => item.photo_id !== photo_obj.photo_id);
        } else {
            this.selectedArray[workId].push(photo_obj);
        }
        return this.selectedArray;
    }
    
}

export function selectionModeArray(arrayTobeUpdate) {
    return new selectionMode(arrayTobeUpdate);
}
  
  