import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { detectBrowser, getAuthToken, get_platform_action_color, get_platform_bg_color, globalLoader, isMobile, isTouch } from "../../script/util";
import axios from "axios";
import { ImageZipper } from '../../script/ImageZipper.js'
import Navbar from '../Navbar/Navbar.jsx';
import { useParams } from 'react-router-dom'
import './DownloadZip.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleInfo, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import StoreToken from '../StoreToken.js';
import { MAX_DOWNLOAD_SIZE_IN_MOBILE } from '../../script/const.js';
import permImage from '../../images/permissionImage.png'
import siteImage from '../../images/siteSettings.png'
import edgePermImg from '../../images/edgePermissions.png'
import edgeAllowImg from '../../images/edgeAllow.png'
import sizeLargeDog from '../../images/sizeLargeDog.png'
import downloadIcon from '../../images/download.png'

const DownloadZip = () => {
    const [downloadData, setDownloadData] = useState(null);
    const [dataKey, setDataKey] = useState(null);
    const [triggerZip, setTriggerZip] = useState(false);
    const [imageUrls, setImageUrls] = useState(false);
    const [progress, setProgress] = useState(0)
    const [currentBatch, setCurrentBatch] = useState()
    const [complete, setComplete] = useState(false)
    const [mobileDownload, setMobileDownload] = useState(false)
    const [popup, setPopup] = useState(false) 
    const [recommend, setRecommend] = useState(false)
    const [downloadInstructions, setDownloadInstructions] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reTrigger, setReTrigger] = useState(false)
    const { storeToLocal } = StoreToken();
    const { key } = useParams();
    const initialFlags = key+'-flags'

    useLayoutEffect(() => {
        storeToLocal();
    }, []);
    if (localStorage.getItem(initialFlags) === null) {
        const value = {
            mobile_download: false,
            download_instructions: true
        };
        localStorage.setItem(initialFlags, JSON.stringify(value));
    }else{

        let allFlags = JSON.parse(localStorage.getItem(initialFlags));

        if (!allFlags.hasOwnProperty('mobile_download')) {
            allFlags.mobile_download = false;
        }
        if (!allFlags.hasOwnProperty('download_instructions')) {
            allFlags.download_instructions = true;
        }
        localStorage.setItem(initialFlags, JSON.stringify(allFlags))
    }

    useEffect(() => {
        setDataKey(key);
        let isCompleted = JSON.parse(localStorage.getItem(key))
        if (isCompleted && isCompleted.every((batch) => batch.is_downloaded)) {
            setComplete(true);
        }
    }, []);

    const handleButtonClick = () => {
        !downloadInstructions && setTriggerZip(true)
    };

    const reDownload = () => {
        var initial = JSON.parse(localStorage.getItem(initialFlags))['mobile_download']
        const mobile = isMobile();

        if (!complete) {
            if (!initial && mobile && Number(downloadData?.size_in_bytes) > MAX_DOWNLOAD_SIZE_IN_MOBILE) {
                setRecommend(true);
            } else if (mobile || !mobile) {
                setPopup(true);
            }
        } else {
            localStorage.removeItem(dataKey);
            localStorage.removeItem(initialFlags);
            window.location.reload();
        }
    }

    const okayReDownload =() =>{
        setPopup(false)
        localStorage.removeItem(dataKey)
        localStorage.removeItem(initialFlags)
        window.location.reload()
    }

    const proceedDownload = useCallback(() => {
        setRecommend(false)
        const value = {
            mobile_download: true,
            download_instructions: JSON.parse(localStorage.getItem(initialFlags))['download_instructions']
        };
        localStorage.setItem(initialFlags, JSON.stringify(value));
        setMobileDownload(true)
        downloadData?.batch_list?.length>1 && setDownloadInstructions(true)
    },[])

    const reTriggerDownload = () => {
        const batchFlags = JSON.parse(localStorage.getItem(dataKey))
        if (batchFlags && Array.isArray(batchFlags)) {
            for (let i = 1; i < batchFlags.length; i++) {
              batchFlags[i].is_downloaded = false;
            }
          
            localStorage.setItem(dataKey, JSON.stringify(batchFlags));
        }
        window.location.reload()
    }

    const handleProgress = (batchIndex, newProgress) => {
        setCurrentBatch(batchIndex)
        setProgress(newProgress);
        if(Math.ceil(newProgress)==100){
            setIsLoading(true)
        }
    };

    const getProgress = (index) => {
        if(index < currentBatch){
            return 100;
        }else if(index == currentBatch){
            return Math.floor(progress)
        }else{
            return 0
        }
    }

    const whyIssue = () => {
        setDownloadInstructions(true)
        const value = {
            mobile_download: JSON.parse(localStorage.getItem(initialFlags))['mobile_download'],
            download_instructions: true
        };
        localStorage.setItem(initialFlags, JSON.stringify(value));
    }

    const okayInstructions = () => {
        const value = {
            mobile_download: JSON.parse(localStorage.getItem(initialFlags))['mobile_download'],
            download_instructions: false
        };
        localStorage.setItem(initialFlags, JSON.stringify(value));
        setDownloadInstructions(false)
        if(currentBatch>1 || complete){
            setReTrigger(true)
        }
    }

    const getStatus = (index) => {
        if(index < currentBatch){
            return <FontAwesomeIcon size='lg' icon={faCircleCheck} style={{ color: get_platform_action_color() }} />;
        }else if(index == currentBatch){
            return `${getProgress(index)}%`
        }else{
            return <FontAwesomeIcon icon={faClock} style={{color: "#000000",}} />
        }
    }

    const targetDiv = document.getElementById('steps')
    const targetPermImg = document.getElementById('permImg')
    const targetAllowImg = document.getElementById('allowImg')
    useEffect(()=>{
        if(downloadInstructions && targetDiv){
            if(detectBrowser()=='Safari'){
                targetDiv.innerHTML = '<p>Safari &gt; Preferences &gt; Downloads &gt; Select Allow</p>'
            }
            if(detectBrowser()=='Edge'){
                targetPermImg.src = edgePermImg
                targetAllowImg.src = edgeAllowImg
            }
        }
    },[downloadInstructions, targetDiv, targetAllowImg, targetPermImg])

    useEffect(() => {
        const downloadBatchFlag = localStorage.getItem('downloadBatch')
        var mobile_download = JSON.parse(localStorage.getItem(initialFlags))['mobile_download']
        var download_instructions = JSON.parse(localStorage.getItem(initialFlags))['download_instructions']
        const downTarget = document.getElementById('downText')
        if(!downloadInstructions){
            if (imageUrls && downloadBatchFlag == "true") {
                !downloadInstructions && setTriggerZip(true)
                if(!downloadInstructions && downTarget)downTarget.innerText = 'Re-download'
            }else if(downloadBatchFlag==null && !complete && imageUrls && dataKey){
                if(!mobile_download && isMobile() && Number(downloadData?.size_in_bytes) > MAX_DOWNLOAD_SIZE_IN_MOBILE){
                    setRecommend(true)
                }else{
                    if(!downloadInstructions && downTarget)downTarget.innerText = 'Re-download'
                    !downloadInstructions && setTriggerZip(true)
                }
            }
        }
        if(download_instructions && !isMobile()){
            downloadData?.batch_list?.length>1 && setDownloadInstructions(true)
        }
    }, [imageUrls, mobileDownload, downloadInstructions])

    
    useEffect(() => {
        const fetchZip = async () => {
            const authToken = getAuthToken();
            const headers = {
                Authorization: authToken,
                "Content-Type": "application/json",
            };
            const response = await fetch(`/api/work/download/${dataKey}/`);

            axios
            .get(`${process.env.REACT_APP_STAG_URL}/api/work/download/${dataKey}/`, { headers })
            .then((response) => {
                setDownloadData(response.data.data);
            })
            .catch((error) => {
                console.error('Error removing image:', error);
            });
            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }
            
        };
        if(dataKey){
            fetchZip();
        }
    }, [dataKey]);

    useEffect(() => {
        if(downloadData){
            setImageUrls(downloadData.batch_list);
        }
    }, [downloadData])

    
    return (
        <>
            {downloadData ? <div>
                <Navbar location={"/download"} title={``}/>
                {!(isTouch() && downloadData?.batch_list?.length>1) ? <>
                    {(currentBatch>=2 || currentBatch==undefined) && downloadData.batch_list.length>1 && <div className='notSavingDiv' style={{backgroundColor: get_platform_bg_color()}}>
                    <div className='savingBar'>
                        <p>Files not downloading? <span style={{textDecoration:"underline", cursor:"pointer"}} onClick={whyIssue}> Why?</span></p>
                    </div>
                    </div>}
                    <div className='downloadBody' style={{paddingTop:currentBatch<=1 && "100px"}}>
                        <button onClick={handleButtonClick}></button>
                        {triggerZip && <ImageZipper 
                            imageUrls={imageUrls} 
                            zipFilename="downloadedImages.zip"
                            triggerZip={triggerZip}
                            onProgress={handleProgress}
                            dataKey={dataKey}
                        />}
                        <h3 className='title'>{downloadData.title} ({downloadData.total_size})</h3>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <div style={{width:"80%", display:"flex", justifyContent:"space-between", flexDirection: window.innerWidth <900 && "column", alignItems: "center"}}>
                                <div style={{width:"185px"}}></div>
                                <div style={{padding:"10px 0"}}>({downloadData.expire_msg})</div>
                                {<div onClick={reDownload} className='reDownload' style={{backgroundColor: get_platform_action_color(), border:`1px solid ${get_platform_action_color()}`, cursor:"pointer", display:"flex", alignItems:"center"}}><img style={{height: window.innerWidth > 600 ? "22px" : "20px", paddingRight:"5px"}} src={downloadIcon} className="invert-image" alt="download"/> <span id='downText'>{complete ? 'Re-download' : 'Download'}</span></div>}
                            </div>
                        </div>
                        <div className="batch-container">
                            {downloadData?.batch_list?.map((batch, index) => (
                                <div key={batch.batch_name} className="batchDiv">
                                    <div className='progressDetail'>
                                        {(batch.batch_name.length > 6 && window.innerWidth < 900) ? <div className="batch-info">{batch.batch_name.substring(0,6)}... ({batch.batch_size})</div>
                                        : <div className="batch-info">{batch.batch_name} ({batch.batch_size})</div>}
                                        <div className="progress-bar-percent">
                                            {complete ? <FontAwesomeIcon size='lg' icon={faCircleCheck} style={{ color: get_platform_action_color() }} /> : getStatus(index)}
                                        </div>
                                    </div>
                                    <div style={{width:"100%"}}>
                                        {getProgress(index)>0 && getProgress(index)<100 && <p style={{width:"100%", textAlign:"left", fontStyle:"italic", color:"#6c757d", fontSize:"18px"}}>Generating Zip...</p>}
                                        <div className="progress-bar-container">
                                            <div
                                                className="progress-bar"
                                                style={{ width: `${complete ? '100%' : getProgress(index)}%`, backgroundColor: get_platform_bg_color() }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {popup && <div className='progressLost'>
                        <div style={{background:"white", padding:"10px 22px", borderRadius:"10px"}}>
                            <p style={{padding:"10px 0"}}>All the progress will be lost!</p>
                            <div style={{textAlign:"right", fontSize:"small"}}>
                                <button style={{border:"1px solid black", padding:'2px 8px', borderRadius:"5px", marginRight:"10px"}} onClick={()=>setPopup(false)}>Cancel</button>
                                <button style={{border:`1px solid ${get_platform_action_color()}`, backgroundColor: get_platform_action_color(), color:"white", padding:"2px 8px", borderRadius:"5px"}} onClick={okayReDownload}>Okay</button>
                            </div>
                        </div>
                    </div>}
                    {recommend && <div className='progressLost'>
                        <div style={{background:"white", padding:"10px 22px", borderRadius:"10px", maxWidth:"90%"}}>
                            <p style={{padding:"10px 0", textAlign:"left"}}>We don't recommend this download in mobile device. Since the download size is <span style={{fontWeight:"900"}}>{downloadData.total_size}.</span></p>
                            <div style={{textAlign:"right", fontSize:"small"}}>
                                <button style={{border:"1px solid black", padding:'2px 8px', borderRadius:"5px", marginRight:"10px"}} onClick={()=>setRecommend(false)}>Cancel</button>
                                <button style={{border:`1px solid ${get_platform_action_color()}`, backgroundColor: get_platform_action_color(), color:"white", padding:"2px 8px", borderRadius:"5px"}} onClick={proceedDownload}>Proceed</button>
                            </div>
                        </div>
                    </div>}
                    <div className='progressLost' style={{display: !downloadInstructions && 'none'}}>
                            <div className='downloadInst'>
                                <div style={{display:'flex', padding:"15px 0", justifyContent:"center", width:"100%"}}>
                                    <FontAwesomeIcon icon={faCircleInfo} style={{color: "#000000", padding:"5px"}} /> {" "}
                                    <p style={{textAlign:"left", fontWeight:"900"}}>Enable multiple file downloads in your browser for a smoother experience.</p>
                                </div>
                                <div style={{display:(detectBrowser()=='Chrome' || detectBrowser()=='Edge') ? "flex" : 'none',justifyContent:"space-between", alignItems:"center", flexDirection: window.innerWidth<600 && 'column'}}>
                                    <div style={{width:window.innerWidth<600 ? "80%" : "45%"}}><img id='permImg' src={siteImage}/></div>
                                    <div style={{fontSize:"40px", transform: window.innerWidth<600 && 'rotate(90deg)'}}>{`>`}</div>
                                    <div style={{width:window.innerWidth<600 ? "100%" : "45%"}}><img id='allowImg' src={permImage}/></div>
                                </div>
                                <div id='steps' style={{fontSize:"25px"}}></div>
                                <div style={{textAlign:"right", padding:"15px 0"}}>
                                    <button style={{border:`1px solid ${get_platform_action_color()}`, backgroundColor: get_platform_action_color(), color:"white", padding:"2px 8px", borderRadius:"5px"}} onClick={okayInstructions}>Okay</button>
                                </div>
                            </div>
                    </div>
                    {isLoading && <div
                        className='overlayLoader'
                        style={{color:"white", fontSize:"large"}}
                        >
                        {globalLoader()}<div style={{paddingTop:"1%"}}>Initiating your download!</div>
                        </div>}
                    </> : <div className="noPhotoDiv" style={{paddingTop:"200px", height:"60vh"}}>
                                    <img
                                    style={{height:"90%"}}
                                    className="noPhoto"
                                    src={sizeLargeDog}
                                    alt="No Photos Available"
                                    />
                                    <p style={{padding:"30px", fontWeight:"100"}}>Download size is too large <span style={{fontWeight:"900"}}>({downloadData?.total_size})</span>.<br/> Use a desktop or keep it under <span style={{fontWeight:"900"}}>{downloadData?.batch_size}</span> for mobile.</p>
                                </div>}
                     </div> : <div
                    className='overlayLoader'
                    >
                    {globalLoader()}
                    </div>}
                {reTrigger && <div className='progressLost'>
                        <div className='reDownloadDiv'>
                            <div>Re-download files which couldn't be downloaded.</div>
                            <div style={{textAlign:"right", padding:"10px 0"}}>
                                <button style={{padding:"5px 12px", border:"1px solid black", borderRadius:"5px", marginRight:"5px"}} onClick={()=>setReTrigger(false)}>Cancel</button>
                                <button style={{padding:"5px 12px", borderRadius:"5px", background: get_platform_action_color(), color:"white"}} onClick={reTriggerDownload}>Re-download</button>
                            </div>
                        </div>
                </div>}
        </>
    );
};

export default DownloadZip;