import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = () => (
  <div className="work-detail-skeleton">
    <Skeleton className="skeleton-image" height={200} width={`100%`} />
    <div className="skeleton-text">
      <Skeleton height={20} width={`60%`} />
      <Skeleton height={20} width={`80%`} />
    </div>
  </div>
);

export default SkeletonLoader;
