import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import cameraIcon from '../../images/camera.png'
import './FaceSearch.css'
import { useStateContext } from '../../context/StateContext'
import StoreToken from '../StoreToken'
import SkeletonLoader from '../SkeletonLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { getAPIUrl, getAuthToken, canvasToDataURL, get_platform_bg_color, get_platform_action_color } from '../../script/util'
import { useNavigate } from 'react-router-dom'
import { CameraOff } from "lucide-react";
import faceRecognition from '../../images/face-recognition.png'

const FaceSearch = ({ updateFaceSearchResult}) => {
    const [hasPermission, setHasPermission] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [isStreamActive, setIsStreamActive] = useState(false);
    const videoRef = useRef(null);
    const streamRef = useRef(null);
    const { isLoggedin } = useStateContext();
    const { storeToLocal } = StoreToken();
    const navigate = useNavigate();
    useLayoutEffect(() => {
      storeToLocal();
    }, []);
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    const shared_key = queryParams.get("key");
    const faceSearchOnly = queryParams.get("face_only") === 'true';

    const getCameraStream = async () => {
      try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          setHasPermission(true);
          if (videoRef.current) {
              videoRef.current.srcObject = stream;
              streamRef.current = stream;
              setIsStreamActive(true);
              setErrorMessage("");
          }
      } catch (error) {
          console.error("Camera access denied:", error);
          setHasPermission(false);
          setPermissionDenied(true);
      }
  };

    const cancelFaceSearch = () => {
      document.getElementsByClassName('popupOverlay')[0].style.display = "none";
    };

    const stopStreaming = () => {
      const tracks = streamRef.current?.getTracks();
        tracks?.forEach((track) => track.stop());
        if (videoRef.current) {
          videoRef.current.srcObject = null;
        }
        streamRef.current = null;
    };

    useEffect(() => {
      return () => {
        stopStreaming();
      };
    }, []);

    const searchImage = async (imageData) => {
        const formData = new FormData();
        formData.append("selfie_img", imageData);

        var requestUrl;
        if(shared_key){
          requestUrl = getAPIUrl(`/api/face/search/passcode/${shared_key}/`);
        }else{
          requestUrl = getAPIUrl(`/api/face/search/booking/${id}/`);
        }

        setLoading(true);

        try {
            const response = await axios.post(requestUrl, formData, {
                headers: {
                    "Authorization": getAuthToken(),
                    "Content-Type": "multipart/form-data",
                },
            });

            const tp = response.data;

            if (tp.success) {
              if (streamRef.current) {
                stopStreaming()
                setIsStreamActive(false);
              }
              updateFaceSearchResult(tp.data)
            } else {
              setErrorMessage(tp.message);
            }
        } catch (error) {
            console.error("Error during image search:", error);
            setErrorMessage("Failed to search the image.");
        } finally {
            setLoading(false);
        }
    };

    const noMatchingPhotos = () => {
      setErrorMessage();
      videoRef.current.play();
    }

    const captureImage = (e) => {
      if (hasPermission) {
          e.preventDefault();
          videoRef.current.pause();
          const video = videoRef.current;
          const width = video.videoWidth
          const height = video.videoHeight;
          const imageDataURL = canvasToDataURL(video, width, height)
          searchImage(imageDataURL);
      } else {
          document.getElementsByClassName('popupOverlay')[0].style.display = "flex";
      }
    };

    const cancelSearch = () => {
      if (streamRef.current) {
        stopStreaming();
        setIsStreamActive(false);
      }
      queryParams.delete("face-search");
      navigate({ search: queryParams.toString()}, { replace: true });
    }

    useEffect(() => { 
      navigator.permissions.query({ name: 'camera' }).then(permissionStatus => {
          if(permissionStatus.state === 'denied') {
              setPermissionDenied(true);
          }
      });
    }, []);

    useEffect(() => {
      const handleClick = () => {
        const ele = document.getElementById('initialStart');
        if (ele) {
          ele.click();
        }
      };
  
      const timer = setTimeout(handleClick, 100);
  
      return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
      return () => {
        if (streamRef.current) {
          cancelSearch();
        }
      };
    }, []);

  return (
    <>
      {isLoggedin ? <div>
        <button id='startFirst' onClick={cancelSearch} className='cancelSearch' style={{display: faceSearchOnly && 'none'}}>
            <FontAwesomeIcon size='xl' icon={faArrowLeft} style={{color: "#000000",}} />
        </button>
        {<button id='initialStart' onClick={getCameraStream}></button>}
        <div className='selfie-container' style={{paddingTop: faceSearchOnly ? "0px" : "30px", height: faceSearchOnly && "75vh"}}>
            <div className='tk-slf-content'>
                <div id='face-scanner'>
                    <div className='camera'>
                    <video
                        id='video'
                        ref={videoRef}
                        autoPlay
                        muted
                        playsInline
                        style={{transform: "scaleX(-1)"}}
                    />

                    {!isStreamActive && (
                      <div>
                        <CameraOff size={1} />
                      </div>
                    )}
                    {loading && <div className='scan'></div>}
                    <img
                        src={faceRecognition}
                        alt="face search"
                        className="face-overlay"
                    />
                    </div>
                    <div className='face-btns'>
                        <button onClick={captureImage} style={{backgroundColor: get_platform_action_color()}}><img style={{width:"38px", paddingRight:"10px"}} className='invert-image' src={cameraIcon}/>Capture</button>
                    </div>
                    <h2 id="face-suggest-h2">Align your face & Capture!</h2>
                </div>
            </div>
        </div>
      </div> : <SkeletonLoader />}
      {
        permissionDenied && (
            <div className='popupOverlay'>
              <div className="permissionPopup">
                  <h1>
                  <FontAwesomeIcon icon={faCircleInfo} style={{color: "#000000",}} /> Allow EventGraphia to access your camera
                  </h1>
                <h2 id="fullviewH2">
                  You are required to allow your camera permissions to use this feature
                </h2>
                <h3>Privacy and security &gt; Site settings &gt; Allow Camera</h3>
                <button id="popup-cnl" onClick={cancelFaceSearch}>Okay</button>
              </div>
            </div>
        )
      }
      {
        errorMessage && <div className='errorOverlay'>
          <div className='errorMessage'>
            <p>{errorMessage}</p>
            <button className='okPhotoButton' style={{backgroundColor: get_platform_bg_color()}} onClick={noMatchingPhotos} >OK</button>
          </div>
        </div>
      }
      {
        loading && <div className='loadingOverlay'>
          <div className='loadingMessage'>
            {!faceSearchOnly && <SkeletonLoader />}
          </div>
        </div>
      }
    </>
  )
}

export default FaceSearch
