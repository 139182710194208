import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useStateContext } from "../../context/StateContext";
import StoreToken from "../../components/StoreToken";
import { globalLoader, defaultQuality, get_platform_bg_color, getAuthToken, catchError, getMobileOperatingSystem, areObjectsEqual, getWhatsappShareUrl, get_platform_action_color, getApiUrlFromSelectionType, token_url, isAdmin, checkPlurality, isMobile } from "../../script/util";
import axios from "axios";
import './WorkDetails.css'
import Masonry from "react-masonry-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPlay, faXmark, faArrowUp, faArrowRight, 
         faPlus, faChevronLeft, faArrowLeft, 
         faCircleCheck, faRotateRight, 
         faAngleLeft, faAngleRight,
         faCopy, faExternalLinkAlt,
         faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import VideoPlayer from "../../components/VideoPlayer.js";
import AlbumView from "../../components/AlbumView/AlbumView.js";
import Navbar from '../../components/Navbar/Navbar.jsx'
import SkeletonLoader from "../../components/SkeletonLoader.js";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import videoIcon from '../../images/video.png'
import imageIcon from '../../images/camera.png'
import albumIcon from '../../images/drawing-book.png'
import selfieIcon from '../../images/face-scan.png'
import hideIcon from '../../images/hide.png'
import whiteHideIcon from '../../images/Hide-Button-White.png'
import redHideIcon from '../../images/Hide-Button-Red.png'
import heartIcon from '../../images/heart.png'
import shareIcon from '../../images/share.png'
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Toast from "../../components/Toast/Toast.js";
import copy from "clipboard-copy";
import { selectionModeArray } from '../../script/SelectionMode.js'
import { faCircleCheck as hollowCheck } from "@fortawesome/free-regular-svg-icons";
import noPhotoDog from '../../images/noPhoto.png'
import filter from '../../images/sort.png'
import FaceSearch from "../../components/FaceSearch/FaceSearch.js";
import Popup from "../../components/Popup/Popup.js";
import downloadIcon from '../../images/download.png'
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const WorkDetail = () => {
    const { isLoggedin } = useStateContext();
    const { storeToLocal } = StoreToken();
    const numImages = 72
    const [data, setData] = useState();
    const [collection, setCollection] = useState(null);
    const [bookingPK, setBookingPK] = useState()
    const [videoUrl, setVideoUrl] = useState()
    const defQuality = defaultQuality()
    const [scrollPosition, setScrollPosition] = useState(0);
    const [galleryImages, setGalleryImages] = useState()
    const [hdUrl, setHdUrl] = useState(false) 
    const [showTooltip, setShowTooltip] = useState(false);
    const [albumView, setAlbumView] = useState(false)
    const [videoView, setVideoView] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState()
    const [selectionType, setSelectionType] = useState()
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updateLoader, setupdateLoader] = useState(false)
    const [filterDropdown, setFilterDropdown] = useState(false)
    const [selectedOption, setSelectedOption] = useState('showall')
    const [loadingHD, setLoadingHD] = useState(false);
    const [shareFaceFilter, setShareFaceFilter] = useState(true)
    const [nextCollection, setNextCollection] = useState(false)
    const [nextCollectionName, setNextCollectionName] = useState(null)
    const [prevCollection, setPrevCollection] = useState()
    const [selectionAll, setSelectionAll] = useState({})
    const [photoScroll, setPhotoScroll] = useState(0)
    const [fullScreen, setFullScreen] = useState(false);
    const [bid, setBid] = useState();
    const [indexDisplay, setIndexDisplay] = useState(1);
    const [collectionData, setCollectionData] = useState()
    const [prevScrollPosition, setPrevScrollPosition] = useState(0);
    const [showFilters, setShowFilters] = useState(true)
    const [showArrow, setShowArrow] = useState(false)
    const [hideSelection, setHideSelection] = useState(false)
    const [downloadSelection, setDownloadSelection] = useState(false)
    const [addToSelection, setAddToSelection] = useState(false)
    const [isAddToOpen, setIsAddToOpen] = useState(false)
    const [isSingleAddToOpen, setIsSingleAddToOpen] = useState(false)
    const [selection, setSelection] = useState(false)
    const [selectedImages, setSelectedImages] = useState();
    const [classObj, setClassObj] = useState()
    const [hideSelectedObj, setHideSelectedObj] = useState({})
    const [showGallery, setShowGallery] = useState(false);
    const [gridFirstCheck, setGridFirstCheck] = useState(true)
    const [faceFilter, setFaceFilter] = useState(false)
    const [faceSearchState, setFaceSearchState] = useState(false)
    const [faceCollection, setFaceCollection] = useState()
    const [faceSearchData, setFaceSearchData] = useState()
    const [faceSearchOn, setFaceSearchOn] = useState(false)
    const [pastFaceSearches, setPastFaceSearches] = useState()
    const [croppedImages, setCroppedImages] = useState({});
    const [isScrollable, setIsScrollable] = useState(false)
    const [contentLoading, setContentLoading] = useState(false)
    const [faceFormattedData, setFaceFormattedData] = useState({})
    const [faceOnly, setFaceOnly] = useState(false)
    const [faceSelect, setFaceSelect] = useState({})
    const [faceHide, setFaceHide] = useState({})
    const [multipleSelect, setMultipleSelect] = useState([])
    const [updateSinglePhoto, setUpdateSinglePhoto] = useState(false)
    const [filteredData, setFilteredData] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [downloadDetails, setDownloadDetails] = useState()
    const [selectImages, setSelectImages] = useState()
    const [albumData, setAlbumData] = useState()
    const [defAlbumData, setDefAlbumData] = useState()
    const [singleAlbumData, setSingleAlbumData] = useState()
    const [shareDropdown, setShareDropdown] = useState(false)
    const [shareLinkData, setShareLinkData] = useState()
    const [shareLinkPopup, setShareLinkPopup] = useState(false)
    const [shareSelective, setShareSelective] = useState(false)
    const [shareSelectList, setShareSelectList] = useState({})
    const [shareTab, setShareTab] = useState('photos')
    const [distanceFromTop, setDistanceFromTop] = useState(0);
    const [recallCollection, setRecallCollection] = useState(false)
    const [currentDimensions, setCurrentDimensions] = useState(window?.innerWidth)
    const [pillMargin, setPillMargin] = useState(15)
    const [pastMargin, setPastMargin] = useState(15)
    const [downloadPopup, setDownloadPopup] = useState(false)
    const [qualityVideo, setQualityVideo] = useState(false)
    const [videoDetails, setVideoDetails] = useState()
    const [currentQuality, setCurrentQuality] = useState(1080)
    const [currentQualityUrl, setCurrentQualityUrl] = useState()
    const [readyFaceSearchDownload, setReadyFaceSearchDownload] = useState(false)
    const photos = 'photos'
    const videos = 'videos'
    const albums = 'albums'
    const hide = 'hide'
    const download = 'download'
    const addTo = 'addTo'
    const allPhotos = 'showall'
    const privatePhotos = 'private'
    const publicPhotos = 'public'
    const thumbRef = useRef();
    const scrollContainerRef = useRef(null);
    const thumbState = useRef(false);
    const carouselRef = useRef(null);
    const storyRef = useRef(null)
    const dropdownRef = useRef(null)
    const shareRef = useRef(null)
    const divRef = useRef(null);
    const popupRef = useRef(null)
    const firstCheck = useRef(true)
    const navigate = useNavigate();
    const location = useLocation();
    useLayoutEffect(() => {
        storeToLocal();
    }, []);
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    const tab = queryParams.get("tab")
    const shared_key = queryParams.get("key")
    const collection_id = queryParams.get("work_id")
    const albumStatus = queryParams.get('album_status')
    const videoStatus = queryParams.get('video_status')
    var faceSearchOnly = queryParams.get('face_only') === 'true'
    var currentIndex = 0;
    var currentId = 0;
    var storedCollectionData = {};
    const breakpointColumnsObj = {
        default: 4,
        1300: 4,
        800: 3,
        600: 2,
    };
    var hideData = {}

    const getFilteredData = () => {
        selectedOption === allPhotos && setImages(collectionData[collection_id].data)
        selectedOption === privatePhotos && setImages(hideSelectedObj[collection_id])
        selectedOption === publicPhotos && setImages(
            collectionData[collection_id].data.filter(item => 
                !hideSelectedObj[collection_id].some(hiddenItem => hiddenItem.photo_id === item.photo_id)
            )
        )
    }

    const splitTitle = (title) => {
        if (title.length > 11) {
            const [firstWord, ...rest] = title.split(" ");
            const restOfString = rest.join(" ");
            return [firstWord, restOfString];
        } else {
            return [title]; // Return the title as an array with a single element
        }
    }

    useEffect(() => {
        if (thumbRef.current) {
          var widthThumb = thumbRef.current.clientWidth;
          if (widthThumb > 600) {
            thumbState.current = true;
          }
        }
    }, [thumbRef.current]);


    useEffect(()=>{
        if(faceSearchOnly){
            setFaceOnly(true)
        }
    },[])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (selection || (event.target.tagName === 'A' || event.target.tagName === 'FORM')) {
                event.preventDefault();
                const confirmMessage = "Are you sure you want to navigate away? Your selection will be lost.";
                const confirmed = window.confirm(confirmMessage);
                if (!confirmed) {
                event.preventDefault();
                }
            }
            
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
    
        };
    }, [selection]);

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            closeGalleryHandler()
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const addToDone = () => {
        setIsAddToOpen(false); 
        cancelSelection()
    }

    const downloadVideo = () => {
        const tempAnchor = document.createElement('a')
        tempAnchor.href = currentQualityUrl
        tempAnchor.setAttribute('download', '');
        tempAnchor.click();
        setQualityVideo(false)
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setFilterDropdown(false);
        }
        if (shareRef.current && !shareRef.current.contains(event.target)) {
            setShareDropdown(false);
        }
    };
    
    const exitFaceSearch = ()=>{
        setShareFaceFilter(false)
        setFaceSearchState(false)
        setFaceSearchOn(false)
        setFaceSearchData()
        const bodyDiv = document.getElementsByTagName('body')[0];
        bodyDiv.style.overflow = 'auto';
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('face-search')
        navigate({ search: queryParams.toString()}, { replace: true });
        setCollectionData()
        setRecallCollection(true)
    }

    const indiFaceData = async (id) => {
        setupdateLoader(true)
        const form = new FormData();
        form.append("id", id);
        if(shared_key){
            form.append("key", shared_key);
        }
        const apiUrl = `${process.env.REACT_APP_STAG_URL}/api/face/search/results/photos/`;
        try{
            const response = await axios.post(apiUrl, form, {
                headers: {
                    Authorization: getAuthToken(),
                },
            });
            setFaceFilter(false)
            setShowTooltip(false)
            updateFaceSearchResult(response.data)
        }catch(error){
            catchError(error)
        }
        document.getElementsByTagName('body')[0].style.overflow = 'auto'   
    }

    const getPhotoCountFaceSearch = () => {
        switch (selectedOption) {
            case allPhotos:
                return faceSearchData?.photo_count
            case privatePhotos:
                return Object.values(faceHide).reduce((sum, arr) => sum + arr.length, 0)
            case publicPhotos:
                return (faceSearchData?.photo_count-Number(Object.values(faceHide).reduce((sum, arr) => sum + arr.length, 0)))
        }
    }

    const updateAlbum = (albumId, isChecked, currWorkId=null, photoId=null) => {
        let apiUrl, option;
        if(addToSelection){ // addToSelection will check if the select button is triggered to move in add To selection mode or not
            if(isChecked){
                option = 'add_photo_album'
            }else{
                option = 'remove_photo_album'
            }
            apiUrl = getApiUrlFromSelectionType(addTo)
            apiUrl = `${apiUrl}${albumId}/${option}/`
            setupdateLoader(true)
            const authToken = getAuthToken();
            const headers = {
                Authorization: authToken,
                "Content-Type": "application/json",
            };
            var data;
            if(photoId){
                data = { photo_id_data: [{work_id: Number(currWorkId), select_list: [photoId], unselect_list: []}]}
            }else{
                data = { photo_id_data: multipleSelect };
            }
            axios
            .put(`${apiUrl}`, data, { headers })
            .then((response) => {
                setupdateLoader(false)
                setShowToast(true)
                setToastMessage(response.data.message)
                setSingleAlbumData(response.data.photo_id_map)
                let tempAlbumData = albumData;
                tempAlbumData[albumId]['is_checked'] = isChecked
                setAlbumData(tempAlbumData)
            })
            .catch((error) => {
                console.error('Error removing image:', error);
                setupdateLoader(false)
                setShowToast(true)
                setToastMessage(error.message)
            });
        }
    }

    const checkSelectionLengthValid = () => {
        if(multipleSelect.length==0){
            setShowToast(true);
            setToastMessage('Select atleast one image!')
            return false;
        }else{
            const allSelectedImagesAreEmpty = multipleSelect.every(item => item.select_list.length === 0)
            if(allSelectedImagesAreEmpty){
                setShowToast(true);
                setToastMessage('Select atleast one image!')
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        const targetEle = document.getElementsByClassName('_ZTBlf')[0];
        const actionEle = document.getElementsByClassName('topControls')[0]
        if (targetEle) {
            const handleClick = () => {
                if(targetEle?.getAttribute('aria-label').split(' ')[0]=='Start'){
                    actionEle.style.display = 'none'
                }
                if(targetEle?.getAttribute('aria-label').split(' ')[0]=='Pause'){
                    actionEle.style.display = 'flex'
                }
            };
    
            targetEle.addEventListener('click', handleClick);
    
            return () => {
                targetEle.removeEventListener('click', handleClick);
            };
        }
      }, [showGallery]);

    const handlePlayClick = (event) => {
        const targetEle = document.getElementsByClassName('_ZTBlf')[0]
        const actionEle = document.getElementsByClassName('topControls')[0]
        targetEle.addEventListener('click', function(){
            if(targetEle?.getAttribute('aria-label').split(' ')[0]=='Start'){
                actionEle.style.display = 'none'
            }
            if(targetEle?.getAttribute('aria-label').split(' ')[0]=='Pause'){
                actionEle.style.display = 'flex'
            }
        })
    }

    const handleTabClick = (tab) =>{
        queryParams.delete('face-search')
        queryParams.delete('work_id')
        queryParams.set('tab', tab)
        navigate({ search: queryParams.toString()});
        tab==photos && queryParams.set('work_id', data.data['photo-collection'][0].work_id)
        tab==photos && navigate({ search: queryParams.toString()});
        if(tab==photos && prevCollection == undefined){	
            handleCollections(data.data['photo-collection'][0].work_id)	
        }else if(tab==photos && prevCollection){
            handleCollections(prevCollection)
        }
        if(tab==photos){
            setTimeout(() => {
                getToTop(photoScroll)
            }, 100);
        }else{
            getToTop()
        }
    }

    const faceFilterPhoto = async () => {
        setShareFaceFilter(false)
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
        const form = new FormData();
        var apiUrl
        if(shared_key){
            apiUrl = `${process.env.REACT_APP_STAG_URL}/api/face/search/results/shared/${shared_key}/`;
        }else{
            apiUrl = `${process.env.REACT_APP_STAG_URL}/api/face/search/results/${data.booking_pk}/`;
        }
        setFaceFilter(true)
        setLoading(true)
        try {
            const response = await axios.post(apiUrl, form, {
                headers: {
                    Authorization: getAuthToken()
                },
            });
            setPastFaceSearches(response.data)
            !response.data.length>0 && !faceSearchOnly && faceSearch()
        } catch (error) {
            setLoading(false)
            catchError(error)
        }

    }

    const shareMedia = () => {
        setShareDropdown(!shareDropdown)
    }

    const getShareSelectiveLinkData = async (type, shareData) => {
        var note='';
        const apiUrl = `${process.env.REACT_APP_STAG_URL}/api/share/url/${data.booking_pk}/`;
        setShareSelectList([])
        try{
            const response = await axios.post(apiUrl, shareData, {
                headers: {
                    Authorization: getAuthToken(),
                },
            });
            setShareLinkData(response.data)
            setShareLinkPopup(true)
            if(type=='all'){
                note='<div>Note: All new uploaded data will be visible on this link</div>'
            }else if(type=='face_only'){
                note='<div><ul><li>Using this link, guests can click a selfie and only see their photos.</li><li>They will not have access to photos in which they are not present.</li></ul></div>'
            }
            const shareNoteElement = document.getElementById('shareNote');
            if (shareNoteElement) {
                shareNoteElement.innerHTML = note;
            }
            setupdateLoader(false)
        }catch(error){
            catchError(error)
        }
    }

    const switchShareTabs = (switchTabs) => {
        setShareTab(switchTabs)
    }

    const getShareTab = (switchTabs) => {
        if(switchTabs==photos){
            return data?.data['photo-collection']
        }else if(switchTabs==videos){
            return data?.data['video']
        }else if(switchTabs==albums){
            return data?.data['album']
        }else{
            return []
        }
    }
 
    const shareDeliverable = (type) => {
        setShareTab(tab)
        const data = { data_id_list: [] };
        if(type!=='selective'){
            data[type] = true;
            setupdateLoader(true)
        }else{
            setShareSelective(true)
            return;
        }
        getShareSelectiveLinkData(type, data)
    }

    const shareSelection = () => {
        const data = {data_id_list: Object.values(shareSelectList).flat()}
        if(Object.values(shareSelectList).flat().length == 0){
            setShowToast(true)
            setToastMessage('Select atleast one!')
        }else{
            setShareSelective(false)
            getShareSelectiveLinkData('', data)
        }
    }

    const shareSelectMedia = (type, id) => {
        const ele = id.toString();
        setShareSelectList((prevState) => {
            const currentArray = prevState[type] || [];
            return {
                ...prevState,
                [type]: currentArray.includes(ele)
                    ? currentArray.filter((item) => item !== ele)
                    : [...currentArray, ele]
            };
        });
    };
    const shareSelectAllCollection = (type, data) => {
        setShareSelectList((prevState) => {
            const currentSelection = prevState[type] || [];
            if (data.length > 0) {
                const updatedSelection = data.reduce((acc, e) => {
                    let id = e.work_id.toString();
                    if (acc.includes(id)) {
                        return [...acc]
                    } else {
                        return [...acc, id];
                    }
                }, currentSelection);
                
                return { ...prevState, [type]: updatedSelection };
            } else {
                const { [type]: _, ...rest } = prevState;
                return { ...rest, [type]: [] };
            }
        });
    };

    const updateFaceSearchResult = (res) => {
        setFaceOnly(false)
        setFaceSearchOn(true)
        setFaceSearchState(false)
        setFaceCollection(res)
        setFaceSearchData(res)
        setFaceFilter(false)
        setFaceSelect({})
        setImages([])
        setSelectedOption(allPhotos)
        res?.photo_data.map((e) => {
            setFaceFormattedData((prevImages) => ({
                ...prevImages,
                [e.work_id]: e.data,
            }));
        });
        res?.photo_data.map((e) => {
            setFaceSelect((prevImages) => ({
                ...prevImages,
                [e.work_id]: e.private_data,
            }));
        });
        setFaceHide({})
        res?.photo_data.map((e) => {
            setFaceHide((prevImages) => ({
                ...prevImages,
                [e.work_id]: e.private_data,
            }));
        });
        if(res?.photo_data){
            res?.photo_data?.forEach((e)=>{
                    setImages((prev)=>([
                        ...prev,
                        ...e.data
                    ]))
            })
        }else{
            setImages([])
        }
        if(faceSearchOnly){
            if(res?.photo_data){
                res?.photo_data.map((e) => {
                    setSelectImages((prevImages) => ({
                        ...prevImages,
                        [e.work_id]: [],
                    }));
                });
                queryParams.set('work_id', res?.photo_data[0].work_id)
                navigate({ search: queryParams.toString() });
            }
        }
        setupdateLoader(false)
    }

    const addAndRemove = useCallback((work_id, photo_obj, updatedData) => {
        setMultipleSelect((prevMultipleSelect) => {
            return prevMultipleSelect.map((item) => {
                if (item.work_id === work_id) {
                    const isInSelect = updatedData?.some((i) => i.photo_id === photo_obj.photo_id);
                    if (!isInSelect) {
                        return {
                            ...item,
                            select_list: item.select_list.filter((e) => e !== photo_obj.photo_id),
                            unselect_list: [...item.unselect_list, photo_obj.photo_id],
                        };
                    } 
                    else{
                        return {
                            ...item,
                            unselect_list: item.unselect_list.filter((e) => e !== photo_obj.photo_id),
                            select_list: [...item.select_list, photo_obj.photo_id],
                        };
                    }
                }
                return item;
            });
        });
    }, []);

    const selectAllUpdateMultiple = (status, work_id) => {
        const selectedKey = work_id 
        setMultipleSelect((prevMultipleSelect) => {
            const index = prevMultipleSelect.findIndex((obj) => obj.work_id === Number(selectedKey));
            if(!faceSearchOn){
                if (index === -1) {
                    return [
                        ...prevMultipleSelect,
                        {
                            work_id: Number(selectedKey),
                            select_list: status 
                                ? collectionData[selectedKey].data.map((obj) => obj.photo_id) 
                                : [],
                            unselect_list: status 
                                ? [] 
                                : collectionData[selectedKey].data.map((obj) => obj.photo_id),
                        }
                    ];
                } else {
                    return prevMultipleSelect.map((item, idx) => 
                        idx === index 
                            ? {
                                ...item,
                                select_list: status 
                                    ? collectionData[selectedKey].data.map((obj) => obj.photo_id) 
                                    : [],
                                unselect_list: status 
                                    ? [] 
                                    : collectionData[selectedKey].data.map((obj) => obj.photo_id),
                            }
                            : item
                    );
                }
            }else{
                if (index === -1) {
                    return [
                        ...prevMultipleSelect,
                        {
                            work_id: Number(selectedKey),
                            select_list: status 
                                ? faceFormattedData[selectedKey].map((obj) => obj.photo_id) 
                                : [],
                            unselect_list: status 
                                ? [] 
                                : faceFormattedData[selectedKey].map((obj) => obj.photo_id),
                        }
                    ];
                } else {
                    return prevMultipleSelect.map((item, idx) => 
                        idx === index 
                            ? {
                                ...item,
                                select_list: status 
                                    ? faceFormattedData[selectedKey].map((obj) => obj.photo_id) 
                                    : [],
                                unselect_list: status 
                                    ? [] 
                                    : faceFormattedData[selectedKey].map((obj) => obj.photo_id),
                            }
                            : item
                    );
                }
            }
        });
    };

    const updateSingleSelect = useCallback((work_id, photo_obj, updatedData) => {
        work_id = Number(work_id)
        const index = multipleSelect.findIndex((obj) => obj.work_id === work_id);
        if (index !== -1) {
            addAndRemove(work_id, photo_obj, updatedData);
        } else {
            setMultipleSelect((prevMultipleSelect) => [
                ...prevMultipleSelect,
                {
                    work_id,
                    select_list: [],
                    unselect_list: [],
                },
            ]);
            addAndRemove(work_id, photo_obj, updatedData);
        }
    }, [multipleSelect, hideSelectedObj, collectionData]);


    const closeFacePopup = () => {
        setFaceFilter(false)
        document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }

    const faceSearch = () => {
        setSelection(false)
        setDownloadSelection(false)
        setHideSelection(false)
        if(faceSearchOnly){
            setFaceOnly(true);
        }else{
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set('face-search', true)
            navigate({ search: queryParams.toString()});
            setFaceFilter(false)
            setFaceSearchState(true)
            setShowTooltip(false);
            document.getElementsByTagName('body')[0].style.overflow = 'auto'
        }
    }

    const galleryHandler = (event) => {
        if(!hideSelection){
            setFilterDropdown(false)
            setBid(parseInt(event?.currentTarget.id, 10));
            setIndexDisplay(event?.currentTarget.id);
            if(!event){
                setBid(parseInt(location.hash.split('=')[1], 10))
                setIndexDisplay(location.hash.split('=')[1])
            }
            setShowGallery(true);
            const bodyDiv = document.getElementsByClassName('my-masonry-grid')[0];
            if(bodyDiv)bodyDiv.style.display = 'none';
        }
    };

    const toggleSelectImage = (photo_obj='') => {
        const selectedKey = faceSearchOn ? Object.keys(faceFormattedData).find((key) => 
            faceFormattedData[key].some(obj => obj.photo_id === photo_obj.photo_id)
        ) : collection_id
        var currentSelected;
        if(hideSelection){
            currentSelected = selectionTypeData(hide, selectedKey)
        }else if(downloadSelection){
            currentSelected = selectionTypeData(download, selectedKey)
        }else if(addToSelection){
            currentSelected = selectionTypeData(addTo, selectedKey)
        }
        const updatedObj = classObj.updateSelectedArray({[collection_id]: currentSelected}, photo_obj, selectedKey);
        if(faceSearchOn){
            !downloadSelection && setFaceSelect(prevSelectedImages => ({
                ...prevSelectedImages,
                [selectedKey]: updatedObj[selectedKey]
            }));
            const totalItems = Object.values(updatedObj).reduce((sum, arr) => sum + arr.length, 0)
            setSelectionAll(prevState => ({
                ...prevState,
                [collection_id]: totalItems === faceSearchData?.photo_count
            }));
        }else{
            !downloadSelection && setSelectedImages(prevSelectedImages => ({
                ...prevSelectedImages,
                [selectedKey]: updatedObj[selectedKey]
            }));
            setSelectionAll(prevState => ({
                ...prevState,
                [collection_id]: updatedObj[collection_id].length === collectionData[collection_id].data.length
            }));
        }
        (downloadSelection || addToSelection) && setSelectImages((prev)=>({
            ...prev,
            [selectedKey] : updatedObj[selectedKey]
        }))
        updateSingleSelect(selectedKey, photo_obj, updatedObj[selectedKey])
        let tempData = defAlbumData;
        setAlbumData(JSON.parse(JSON.stringify(tempData))); // Create a deep copy
    };

    const updateSelectedPhotos = () => {
        if(isAdmin()){
            return;
        }else{
            if(downloadSelection){
                if(!checkSelectionLengthValid()){
                    return;
                }
            }
            var apiUrl = '';
            if(selectionType !== addTo){
                apiUrl = getApiUrlFromSelectionType(selectionType)
            }else{
                return;
            }
            setupdateLoader(true)
            const authToken = getAuthToken();
            const headers = {
                Authorization: authToken,
                "Content-Type": "application/json",
            };
            const data = { photo_id_data: multipleSelect };
            if(shared_key){
                data['shared_key'] = shared_key
            }
            
            axios
            .post(`${apiUrl}`, data, { headers })
            .then((response) => {
                setHideSelectedObj(selectedImages)
                if(collection)collection.private_data = selectedImages[collection_id]
                setFaceHide(faceSelect)
                setMultipleSelect((prevSelect)=>
                    prevSelect.map(item=>({
                        ...item,
                        select_list: [],
                        unselect_list: []
                })))
                setSelectImages((prev) => {
                    const emptyArrays = Object.keys(prev).reduce((acc, key) => {
                        acc[key] = [];
                        return acc;
                    }, {});
                    return emptyArrays;
                });
                setSelectionAll({})
                if(downloadSelection || selectionType==download){
                    setDownloadDetails(response.data)
                    setIsPopupOpen(true)
                }
                setHideSelection(false)
                setDownloadSelection(false)
                setSelection(false)
                selectedOption !== allPhotos && handleFilterCheckboxChange(selectedOption)
                setupdateLoader(false)
                setUpdateSinglePhoto(false)
                if(selectionType !== download){
                    setShowToast(true)
                    setToastMessage(response.data.message)
                }
            })
            .catch((error) => {
                console.error('Error removing image:', error);
                setupdateLoader(false)
                setShowToast(true)
                setToastMessage(error.message)
            });
        }
    }

    const downloadFaceSearch = () => {
        setSelectionType(download)
        Object.keys(faceFormattedData).forEach((key) =>
            selectAllUpdateMultiple(true, key)
        );
        setReadyFaceSearchDownload(true)
    }

    const play = document.getElementsByClassName("_ZTBlf");
    if (play[0]) play[0].style.marginLeft = "50px";

    const fullSize = document.getElementsByClassName("_lfOsC _dZ8C-");
    if (fullSize[1]) {
        fullSize[1].addEventListener("click", () => {
        const elem = thumbRef.current;
        if (!document.fullscreenElement) {
            setFullScreen(true);
            if (elem.requestFullscreen) {
            elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
            }
        } else {
            setFullScreen(false);
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
            carouselRef.current.toggleIsPlaying()
        }
        });
    }

    const handleIndexChange = (e) => {
        let num = parseInt(e.curIndexForDisplay, 10);
        setIndexDisplay(num);
        setBid(num);
        window.location.hash = `#gallery?index=${num}`
        if(images.length<indexDisplay){
            selectedOption !== allPhotos ? handleFilterCheckboxChange(selectedOption) : setImages(collection.data.slice(0, images?.length + numImages))
        }
    };

    const okayHide = () => {
        document.getElementById('grideHidePopup').style.display = 'none';
        setGridFirstCheck(false)
    }

    const closeGalleryHandler = async () => {
        setFaceSearchState(false)
        setShowGallery(false);
        const bodyDiv = document.getElementsByClassName('my-masonry-grid')[0];
        if(bodyDiv)bodyDiv.style.display = 'flex';
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname + window.location.search
        );
        const element_scroll = document.getElementById(`gallery${indexDisplay}`);
        element_scroll?.scrollIntoView({ behavior: "smooth" });
        location.hash=''
        fetchImages()
        handleFilterCheckboxChange(selectedOption)
    };

    const getDataByOption = (ele, selectedOption) => {
        switch (selectedOption) {
            case privatePhotos:
              return faceHide[ele?.work_id];
            case publicPhotos:
              return ele?.data.filter(obj1 => !faceHide[ele?.work_id].some(obj2 => obj2.photo_id === obj1.photo_id));
            default:
              return ele?.data;
          }
    }

    const handleAlbum = (albumId) =>{
        handleCollections(albumId)
        queryParams.set('work_id', albumId)
        queryParams.set('album_status', true)
        navigate({ search: queryParams.toString()});
        setAlbumView(true)
    }

    const closeAlbum = () =>{
        queryParams.delete('album_status')
        queryParams.delete('work_id')
        navigate({ search: queryParams.toString()});
        setAlbumView(false)
        setShowFilters(true)
    }

    const handleVideo = (videoId) =>{
        handleCollections(videoId)
        queryParams.set('work_id', videoId)
        queryParams.set('video_status', true)
        navigate({ search: queryParams.toString() });
        setVideoView(true)
    }

    const confirShareLost = () => {
        if(Object.values(shareSelectList).flat().length == 0 ){
            setShareSelective(false);
            setShareSelectList({})
        }else{
            const userConfirmed = window.confirm("All selections will be lost!");
            if (userConfirmed && Object.values(shareSelectList).flat().length > 0) { 
                setShareSelective(false);
                setShareSelectList({})
            }
        }
    }

    const closeVideo = () =>{
        queryParams.delete('video_status')
        queryParams.delete('work_id')
        navigate({ search: queryParams.toString()}, { replace: true });
        setVideoView(false)
        setShowFilters(true)
    }

    const getSelectionData = (key) => {
        setSelection(true)
        setSelectionType(key)
        if(key == hide){
            setHideSelection(true)
            selectionMode('', hide)
        }else if(key == download){
            setDownloadSelection(true)
            selectionMode('', download)
        }else if(key == addTo){
            setAddToSelection(true)
            selectionMode('', addTo)
        }
    }

    const qualityPopup = (list) => {
        setVideoDetails(list)
        setQualityVideo(true)
        list.map((e)=>{
            if(e.quality==1080){
                setCurrentQualityUrl(e.url)
            }
        })
    }

    const downloadCollection = () => {
        setupdateLoader(true)
        const authToken = getAuthToken();
        const headers = {
            Authorization: authToken,
            "Content-Type": "application/json",
        };
        const data = { photo_id_data: [{work_id: Number(collection_id), select_list: [], unselect_list: []}]}
        if(shared_key){
            data['shared_key'] = shared_key
        }

        const apiUrl = `${process.env.REACT_APP_STAG_URL}/api/work/url/collection_imgs/${collection_id}/`

        axios
        .post(`${apiUrl}`, data, { headers })
        .then((response) => {
            setDownloadDetails(response.data)
            setIsPopupOpen(true)
            setupdateLoader(false)
        })
        .catch((error) => {
            console.error('Error removing image:', error);
            setupdateLoader(false)
            setShowToast(true)
            setToastMessage(error.message)
        });
    }

    const toggleAddToPopup = () => {
        if(checkSelectionLengthValid()){
            setIsAddToOpen(!isAddToOpen);
        }
    };

    const toggleSingleAddToPopup = () => {
        setIsSingleAddToOpen(!isSingleAddToOpen);
        setAddToSelection(true)
    };

    const checkAlbumKey = (photoId, albumKey) => {
        albumKey = Number(albumKey)
        if(photoId in singleAlbumData){
            for(let i=0; i<singleAlbumData[photoId].length; i++){
                if(singleAlbumData[photoId][i] == albumKey){
                    return true;
                }
            }
        }
        return false;
    };

    const selectionTypeData = (selectionType, currId) => {
        if(selectionType==hide){
            if(selectionAll[currId]){
                return faceSearchOn ? faceFormattedData : collectionData[currId].data
            }else{
                return faceSearchOn ? faceHide : collection?.private_data
            }
        }else if(selectionType==download || selectionType==addTo){
            if(selectionAll[currId]){
                return faceSearchOn ? faceFormattedData : collectionData[currId].data
            }else{
                return faceSearchOn ? Object.keys(faceFormattedData).reduce((acc, key) => {
                    acc[key] = [];
                    return acc;
                }, {}) : []
            }
        }
    }

    const selectionMode = (status, selectionType) => {
        if (images?.length === 0) return;
        const currId = queryParams.get('work_id');
        if (gridFirstCheck && selectionType==hide) {
            document.getElementById('grideHidePopup').style.display = 'flex';
        }
    
        const data = faceSearchOn ? faceFormattedData : collectionData[currId]?.data || [];
        const selectedData = selectionType==download || selectionType==addTo ? selectImages[currId] : (selectedImages[currId] ? selectedImages[currId] : collection?.private_data);
        const totalItems = faceSearchOn && selectionType!==download
            ? Object.values(faceSelect).reduce((sum, arr) => sum + arr.length, 0)
            : selectedData.length;
    
        let arrayToBeUpdated;

        if (status === '') {
            !faceSearchOn ? setSelectionAll((prev) => ({
                ...prev,
                [currId]: totalItems === collection?.data.length || totalItems === data.length
            })) : setSelectionAll((prev) => ({
                ...prev,
                [currId]: totalItems === images.length || totalItems === data.length
            }));
            arrayToBeUpdated = selectionTypeData(selectionType, currId);
        } else if (status === true) {
            arrayToBeUpdated = faceSearchOn ? faceFormattedData : data;
            Object.keys(faceSearchOn ? faceFormattedData : { [currId]: data }).forEach((key) =>
                selectAllUpdateMultiple(true, key)
            );
    
        } else if (status === false) {
            arrayToBeUpdated = faceSearchOn
                ? Object.keys(faceSelect).reduce((acc, key) => ({ ...acc, [key]: [] }), {})
                : [];
            Object.keys(faceSearchOn ? faceFormattedData : { [currId]: data }).forEach((key) =>
                selectAllUpdateMultiple(false, key)
            );
        }
    
        if (status === 'selectAll') {
            const compareData = downloadSelection || addToSelection ? selectImages[currId] : collectionData[currId]?.private_data
            setSelectionAll((prev) => ({
                ...prev,
                [currId]: faceSearchOn ? totalItems === images.length : compareData.length === data.length
            }));
            
        }
    
        if (status !== 'selectAll') {
            const selectedObj = faceSearchOn
                ? selectionModeArray(arrayToBeUpdated)
                : selectionModeArray({ [collection_id]: [...arrayToBeUpdated] });
            setClassObj(selectedObj);
        }
    };
    

    const cancelSelection = () => {
        if(addToSelection){
            setMultipleSelect((prevSelect)=>
                prevSelect.map(item=>({
                    ...item,
                    select_list: [],
                    unselect_list: []
            })))
            setSelectImages((prev) => {
                const emptyArrays = Object.keys(prev).reduce((acc, key) => {
                    acc[key] = [];
                    return acc;
                }, {});
                return emptyArrays;
            });
        }
        setHideSelection(false)
        setDownloadSelection(false)
        setAddToSelection(false)
        setSelection(false)
        setSelectionAll({})
        if(faceSearchOn){
            setFaceSelect(faceHide)
        }else{
            setSelectedImages(hideSelectedObj)
        }
        setImages([])
        selectImages && setSelectImages((prev) => {
            const emptyArrays = Object.keys(prev).reduce((acc, key) => {
                acc[key] = [];
                return acc;
            }, {});
            return emptyArrays;
        });
        if(!faceSearchData?.photo_data){
            getFilteredData()
        }else{
            faceSearchData?.photo_data?.forEach((e)=>{
                setImages((prev)=>([
                    ...prev,
                    ...e.data
            ]))
        })
        }
    }


    const getToTop = (top=0) => {
        if(top==0){
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }else{
            window.scrollTo({
                top: top,
                behavior: 'auto'
            });
        }
    };

    const handleNextCollection = () => {
        data.data['photo-collection'].forEach((e, index)=>{
            if(e.work_id == collection_id && index < data.data['photo-collection'].length-1){
                data.data['photo-collection'][index+1].work_id && handleCollections(data.data['photo-collection'][index+1].work_id)
            }
        })
    }

    const switchHD = async () => {
        if(collection?.is_downloadable || faceSearchData?.is_downloadable){
            setLoadingHD(true);
            setHdUrl((prev)=>!prev)
        }else {
            setShowToast(true)
            setToastMessage('HD not available') 
        }
    }

    const downloadImage = (image='') => {
        const checkIOS = getMobileOperatingSystem()
        if(image || (collection?.is_downloadable || faceSearchData?.is_downloadable)){
            var link = document.createElement("a");
            var downloadUrl = hdUrl
            ? galleryImages[bid - 1].src
            : galleryImages[bid - 1].src;
            if(checkIOS !== "iOS"){
                link.href = image ? image.download_url : downloadUrl
                link.download = image ? `${image.identifier}.jpg` : `${galleryImages[bid - 1].identifier}.jpg`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                window.open(`${process.env.REACT_APP_STAG_URL}/ios_download/?url=` + encodeURIComponent(downloadUrl), '_blank');
            }
        }else{
            setShowToast(true)
            setToastMessage('No download permission') 
        }
    };

    const handleFilterCheckboxChange = (filter) => {
        setSelectedOption(filter)
        if(faceSearchOn){
            setFaceSearchData(faceCollection)
        }else{
            if(filter == allPhotos){
                setImages(collection?.data)
            }else if(filter == privatePhotos){
                setImages(selectedImages[collection_id])
            }else if(filter == publicPhotos){
                setImages(collection?.data?.filter(img => !selectedImages[collection_id].some(el=> img.photo_id === el.photo_id)))
            }
        }
    }

    const handleCollections = async (workId)=>{
        setRecallCollection(false)
        tab==photos && setPrevCollection(workId)
        setCollection(null)
        setFilterDropdown(false)
        setSelectedOption(selectedOption)
        const imagesLength = images?.length
        setImages(null)
        let dataStore = collectionData;
        dataStore = dataStore ? dataStore : {};
        queryParams.set('work_id', workId)
        navigate({ search: queryParams.toString() });
        if(Object.keys(dataStore) && Object.keys(dataStore)?.length !== 0 && 
            dataStore.hasOwnProperty(workId) && 
            dataStore[workId].expires_at > Date.now()){
            dataStore[workId].private_data = selectedImages[workId]
            setCollection(dataStore[workId])
            !hideSelection && setSelectedImages((prevState) => ({
                ...prevState,
                [workId]: hideSelectedObj[workId]
            }))
            queryParams?.get('tab')==photos && photoScroll == 0 && setImages(dataStore[workId].data.slice(0, numImages))
            hideSelection && selectionMode('selectAll', hide)
            downloadSelection && selectionMode('selectAll', download)
            addToSelection && selectionMode('selectAll', addTo)
            if(faceSearchData?.photo_data){
                faceSearchData?.photo_data.map((e)=>{
                    if(e.work_id==workId){
                        setImages(e.data)
                    }
                })
            }
            (queryParams?.get('tab')==photos &&  photoScroll > 0 && (dataStore[workId].data?.length < numImages || imagesLength > numImages)) ? setImages(dataStore[workId].data.slice(0, imagesLength)) : setImages(dataStore[workId].data.slice(0, numImages))
            setBookingPK(dataStore[workId].booking_pk)
            if(tab==videos && dataStore[workId].url_list){
                for(let i = dataStore[workId].url_list?.length; i>0; i--){
                    if(dataStore[workId].url_list[i-1].quality===defQuality){
                        setVideoUrl(dataStore[workId].url_list[i-1].url)
                    }
                }
            }
            selectedOption !== allPhotos && setFilteredData(true)
        }else{
            const form = new FormData();
            form.append("client_type", "mobile");

            if (shared_key) {
                form.append("key", shared_key);
            }
            const api_url = `${process.env.REACT_APP_STAG_URL}/api/work/${workId}/`;
            setContentLoading(true)
            try {
                const response = await axios.post(api_url, form, {
                    headers: {
                        Authorization: getAuthToken(),
                        "Content-Type": "multipart/form-data",
                    },
                });
                dataStore[workId] = response.data
                hideData[workId] = response.data.private_data
                setCollectionData(dataStore)
                storedCollectionData = dataStore
                setCollection(response.data);
                let tempData = response.data?.album_data?.album_data || {};
                setDefAlbumData(JSON.parse(JSON.stringify(tempData))); // Create a deep copy
                setAlbumData(tempData);
                setSingleAlbumData(response.data?.album_data?.photo_id_map || {})
                setHideSelectedObj((prev) => ({
                    ...prev, 
                    ...hideData
                }));
                setSelectedImages((prevState) => ({
                    ...prevState,
                    [workId]: response.data.private_data
                }))
                setSelectImages((prev)=>({
                    ...prev,
                    [workId]: []
                }))
                setSelectionAll((prev)=>({
                    ...prev,
                    [workId]: false
                }))
                hideSelection && selectionMode('selectAll', hide)
                queryParams?.get('tab')==photos && setImages(response?.data?.data?.slice(0, numImages))
                if(location.hash.includes('#gallery')){
                    setShareFaceFilter(false)
                    setShowGallery(true)
                    window.location.hash = location.hash
                    galleryHandler()
                }
                setBookingPK(response.data.booking_pk)
                if(tab==videos){
                    for(let i = response.data.url_list?.length; i>0; i--){
                        if(response.data.url_list[i-1].quality===defQuality){
                            setVideoUrl(response.data.url_list[i-1].url)
                        }
                    }
                }
                setContentLoading(false)
                selectedOption !== allPhotos && setFilteredData(true)
            } catch (error) {
                setContentLoading(false)
                catchError(error)
                if(tab){
                    handleTabClick(tab)
                }else{
                    handleTabClick(photos)
                }
            }
        }
        getToTop()
    }

    useEffect(()=>{
        if(recallCollection){
            const unique = queryParams.get('work_id')
            handleCollections(unique)
        }
    },[recallCollection])

    useEffect(()=>{
        if(readyFaceSearchDownload){
            updateSelectedPhotos()
            setReadyFaceSearchDownload(false)
        }
    },[readyFaceSearchDownload])

    const handleOrientationChange = () => {
        setCurrentDimensions(window.innerWidth)
        const container = storyRef.current;
        const pastContainer = scrollContainerRef.current
        if(container?.clientWidth < container?.scrollWidth){
            if (window.innerWidth >= 1200) {
                if (container) {
                    const containerWidth = container.offsetWidth;
                    const pillWidth = 220 + 30;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    const visiblePillMargin = remainingSpace / 2;
                    var margin = Math.floor(visiblePillMargin/(pillsNum*2))
                    setPillMargin(10+margin)
                }
            }else if(window.innerWidth < 1200 && window.innerWidth >= 900){
                if (container) {
                    const containerWidth = container.offsetWidth;
                    const pillWidth = 100 + 74;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    var margin = Math.floor(remainingSpace/(pillsNum*2))
                    setPillMargin(18+margin)
                }
            }else if(window.innerWidth < 900 && window.innerWidth >= 600){
                if (container) {
                    const containerWidth = container.offsetWidth;
                    const pillWidth = 100 + 47;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    var margin = Math.floor(remainingSpace/(pillsNum*2))
                    setPillMargin(17+margin)
                }
            }else if(window.innerWidth < 600){
                if (container) {
                    const containerWidth = container.offsetWidth;
                    const pillWidth = 75 + 30;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    var margin = Math.floor(remainingSpace/(pillsNum*2))
                    setPillMargin(9+margin)
                }
            }
        }
        if(pastContainer?.clientWidth < pastContainer?.scrollWidth){
            if(window.innerWidth >= 600){
                if (pastContainer) {
                    const containerWidth = pastContainer.offsetWidth;
                    const pillWidth = 100 + 30;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    var margin = Math.floor(remainingSpace/(pillsNum*2))
                    setPastMargin(10+margin)
                }
            }else{
                if (pastContainer) {
                    const containerWidth = pastContainer.offsetWidth;
                    const pillWidth = 80 + 20;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    var margin = Math.floor(remainingSpace/(pillsNum*2))
                    setPastMargin(5+margin)
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleOrientationChange);
    
        return () => {
          window.removeEventListener('resize', handleOrientationChange);
        };
    }, []);

    useEffect(()=>{
        handleOrientationChange()
    },[showArrow])

    useEffect(()=>{
        if(photoScroll==0 && divRef.current && tab==photos && !contentLoading){  //it gets height of div from the top
            setDistanceFromTop(divRef.current.offsetTop+divRef.current.offsetHeight);
        }
    },[photoScroll, contentLoading]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const faceSearchEnabled = searchParams.get('face-search');
        if (faceSearchEnabled === 'true') {
          setFaceSearchState(true)
        }else {
            setFaceSearchState(false)
        }
    }, [location.search]);

    useEffect(()=>{
        if(data && tab==photos){
            data.data['photo-collection'].forEach((e,index)=>{
                if(e.work_id == collection_id){
                    data.data['photo-collection'][index+1] ? setNextCollectionName(data.data['photo-collection'][index+1].title) : setNextCollectionName(null)
                }
            })
        }
    },[collection])

    var unid = queryParams.get('tab')!==photos && collection?.work_id ? collection?.work_id.toString() : ''
    useEffect(()=>{
        const viewDiv = document.getElementById(unid)
        if(!videoView && !albumView && (tab==videos || tab==albums) && viewDiv){
            viewDiv.scrollIntoView({ behavior: "smooth" });
        }
    },[videoView, albumView])

    useEffect(()=>{
        if(faceSearchOnly){
            faceFilterPhoto()
        }
    },[])

    useEffect(() => {
        if (window.location.hash.includes("#gallery")) {
            setShowGallery(true);
        }else{
            setShowGallery(false);
            const bodyDiv = document.getElementsByClassName('my-masonry-grid')[0];
            if(bodyDiv)bodyDiv.style.display = 'flex';
        }
    }, [location]);

    useEffect(()=>{
        if(filteredData){
            getFilteredData(selectedOption)
            setFilteredData(false)
        }
    },[filteredData])

    useEffect(()=>{
        if(collection){
            const handleScroll = () => {
                const position = window.scrollY;
                setScrollPosition(position);
                tab == photos && setPhotoScroll(position)
                if (position > prevScrollPosition && scrollPosition > 200) {
                    setShowFilters(false)
                } else {
                    setShowFilters(true)
                }
                setPrevScrollPosition(position);
                if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight && collection?.data?.length > images?.length + numImages && selectedOption == allPhotos) {
                    setLoading(true);
                    setTimeout(() => {
                        setNextCollection(false);
                        selectedOption !== allPhotos ? handleFilterCheckboxChange(selectedOption) : setImages(collection.data.slice(0, images?.length + numImages));
                        setLoading(false);
                    }, 1000);
                }
                if (window.innerHeight + document.documentElement.scrollTop + 200 > document.documentElement.offsetHeight && collection?.data?.length < images?.length + numImages && selectedOption == allPhotos) {
                    collection.data.length !== images?.length && setLoading(true);
                    setTimeout(() => {
                        setLoading(false);
                        selectedOption !== allPhotos ? handleFilterCheckboxChange(selectedOption) : setImages(collection.data);
                        setNextCollection(true);
                    }, 200);
                }
                if(selectedOption !== allPhotos){
                    setNextCollection(true)
                }
            };

            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    })

    const leftDiv = document.getElementById('storyNav')
    useEffect(()=>{
        if(leftDiv){
            if(leftDiv.scrollWidth > leftDiv.clientWidth){
                setShowArrow(true)
            }
        }
    },[leftDiv])

    useEffect(()=>{
        if(downloadSelection){
            document.getElementById('hideDiv').innerText = 'Download'
        }else if(hideSelection){
            document.getElementById('hideDiv').innerText = 'Update'
        }
        if(!selection){
            tab==photos && fetchImages()
        }
    },[selection])

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];
    
        if ((downloadSelection && !collection?.is_downloadable) || (hideSelection && !collection?.can_make_private) || shareSelective) {
            body.style.overflow = 'hidden';
        } else {
            body.style.overflow = 'auto';
        }
    
        return () => {
            body.style.overflow = 'auto';
        };
    }, [downloadSelection, hideSelection, collection, shareSelective]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && scrollContainer.scrollWidth > scrollContainer.clientWidth) {
            setIsScrollable(true);
        } else {
            setIsScrollable(false);
        }
    }, [loading, pastFaceSearches]);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollLeft -= 100;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 100;
        }
    };

    const scrollStory = (direction) =>{
        if(direction=='left'){
            leftDiv.scrollLeft = leftDiv.scrollLeft - 0.20*leftDiv.scrollWidth
        }else if(direction=='right'){
            leftDiv.scrollLeft = leftDiv.scrollLeft + 0.20*leftDiv.scrollWidth
        }
    }


    const handlePhotoHide = (photo_id) => {
        if(!isAdmin()){
            setSelectionType(hide)
            const selectedKey = faceSearchOn
                ? Object.keys(faceFormattedData).find((key) => 
                    faceFormattedData[key].some(obj => obj.photo_id === photo_id)
                ) : collection_id;
            const work_id = Number(selectedKey);
            const photoObj = faceSearchOn 
                ? faceFormattedData[selectedKey].find((obj) => obj.photo_id === photo_id) 
                : collection.data.find((obj) => obj.photo_id === photo_id);
            if (firstCheck.current) {
                document.getElementById('hidePopupDiv').style.display = 'flex';
                
                const isHidden = faceSearchOn
                    ? faceHide[selectedKey].some(e => e.photo_id === photo_id)
                    : hideSelectedObj[collection_id].some(e => e.photo_id === photo_id);
        
                document.getElementById('hideButtonGallery').innerText = isHidden ? 'Unhide' : 'Hide';
                document.getElementById('popupTitle').innerText = isHidden ? 'Unhide' : 'Hide';
                document.getElementById('popupDesc').innerText = isHidden
                    ? 'Unhidden photos are visible to everyone with whom they are shared'
                    : 'Hidden photos are invisible to everyone with whom they are shared';
        
            } else {
                setMultipleSelect((prevMultipleSelect) => {
                    const index = prevMultipleSelect.findIndex((obj) => obj.work_id === work_id);
        
                    if (index === -1) {
                        prevMultipleSelect = [
                            ...prevMultipleSelect,
                            { work_id, select_list: [], unselect_list: [] },
                        ];
                    }
        
                    return prevMultipleSelect.map((item) => {
                        if (item.work_id === work_id) {
                            const isInSelect = faceSearchOn
                                ? JSON.stringify(faceHide[work_id]).includes(photo_id)
                                : JSON.stringify(collection?.private_data).includes(photo_id);
                            if (isInSelect) {
                                if (!faceSearchOn) {
                                    setSelectedImages((prev) => ({
                                        ...prev,
                                        [work_id]: prev[work_id]?.filter((img) => img.photo_id !== photo_id) || [],
                                    }));
                                } else {
                                    setFaceSelect((prev) => ({
                                        ...prev,
                                        [work_id]: prev[work_id]?.filter((img) => img.photo_id !== photo_id) || [],
                                    }));
                                }
                                setSelectionAll((prev)=>({
                                    ...prev,
                                    [collection_id]: false
                                }))

                                return {
                                    ...item,
                                    select_list: item.select_list.filter((e) => e !== photo_id),
                                    unselect_list: [...item.unselect_list, photo_id],
                                };
                            } else {
                                if (!faceSearchOn) {
                                    setSelectedImages((prev) => ({
                                        ...prev,
                                        [work_id]: [...prev[work_id] || [], photoObj],
                                    }));
                                } else {
                                    setFaceSelect((prev) => ({
                                        ...prev,
                                        [work_id]: [...prev[work_id] || [], photoObj],
                                    }));
                                }
                                return {
                                    ...item,
                                    unselect_list: item.unselect_list.filter((e) => e !== photo_id),
                                    select_list: [...item.select_list, photo_id],
                                };
                            }
                        }
                        return item;
                    });
                });
                setUpdateSinglePhoto(true);
            }
        }
    };

    const toggleSelection = () => {
        var selectionType;
        if(hideSelection)selectionType=hide
        if(downloadSelection)selectionType=download
        if(addToSelection)selectionType=addTo
        setSelectionAll((prevState) => ({
            ...prevState,
            [collection_id]: !selectionAll[collection_id]
        }));
        if(!selectionAll[collection_id]){
            if(hideSelection){
                faceSearchOn ? setFaceSelect(faceFormattedData) : setSelectedImages((prevState)=>({
                    ...prevState,
                    [collection_id]: collectionData[collection_id].data
                }))
            }
            if(downloadSelection || addToSelection){
                !faceSearchOn ? setSelectImages((prevState)=>({
                    ...prevState,
                    [collection_id]: collectionData[collection_id].data
                })) : setSelectImages(faceFormattedData)
            }
            selectionMode(true, selectionType)
        }else{
            !faceSearchOn && !downloadSelection && !addToSelection &&  setSelectedImages((prevState)=>({
                ...prevState,
                [collection_id]: []
            }));
            (downloadSelection || addToSelection) && setSelectImages((prevState)=>({
                ...prevState,
                [collection_id]: []
            }))
            faceSearchOn && setFaceSelect(prevState => {
                const newState = Object.keys(prevState).reduce((acc, key) => {
                  acc[key] = [];
                  return acc;
                }, {});
                return newState;
              });
            selectionMode(false, selectionType)
        }
    }

    const cancelHide = () => {
        firstCheck.current = false
        document.getElementById('hidePopupDiv').style.display='none'
    }

    const handleHideImage = async (photo_id) => {
        firstCheck.current = false
        document.getElementById('hidePopupDiv').style.display='none'
        handlePhotoHide(photo_id)
    }

    const cropImage = (imageUrl, boundingBox, index) => {
        const originalCanvas = document.createElement('canvas');
    
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = imageUrl;
    
        image.onload = function () {
          const ctx = originalCanvas.getContext('2d');
          originalCanvas.width = image.width;
          originalCanvas.height = image.height;
    
          ctx.drawImage(image, 0, 0, image.width, image.height);
    
          const cropX = boundingBox.Left * image.width;
          const cropY = boundingBox.Top * image.height;
          const cropWidth = boundingBox.Width * image.width;
          const cropHeight = boundingBox.Height * image.height;
    
          const croppedCanvas = document.createElement('canvas');
          croppedCanvas.width = cropWidth;
          croppedCanvas.height = cropHeight;
          const croppedCtx = croppedCanvas.getContext('2d');
    
          croppedCtx.drawImage(originalCanvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
    
          const croppedImageDataUrl = croppedCanvas.toDataURL('image/png');
          setCroppedImages(prev => ({ ...prev, [index]: croppedImageDataUrl }));
        };
        
        setTimeout(()=>{
            setLoading(false)
        },100)
        
        image.onerror = function (err) {
          console.error('Failed to load the image', err);
        };
    };

    const downloadMethod = () => {
        setDownloadPopup(true)
    }

    const copyLinkUrl = (link) => {
        setShowToast(true)
        setToastMessage('Copied!')
        copy(link)
    };

    // const collectionApiCall = async (workId) => {
    //     if(workId){
    //         const form = new FormData();
    //         form.append("client_type", "mobile");
    //         if (shared_key) {
    //             form.append("key", shared_key);
    //         }
    //         const api_url = `${process.env.REACT_APP_STAG_URL}/api/work/${workId}/`;
    //         try {
    //             const response = await axios.post(api_url, form, {
    //                 headers: {
    //                     Authorization: getAuthToken(),
    //                     "Content-Type": "multipart/form-data",
    //                 },
    //             });
    //             storedCollectionData[workId] = response.data
    //             if(collectionData && !(workId in collectionData))setCollectionData(storedCollectionData)
    //             let uniq = data?.data['photo-collection'][currentId].work_id
    //             while (uniq in storedCollectionData) {
    //                 currentId++;
    //                 uniq = data?.data['photo-collection'][currentId]?.work_id;
    //             }
    //             if (!(uniq in storedCollectionData)) {
    //                 collectionApiCall(uniq)
    //             }
    //         } catch (error) {
    //             setContentLoading(false)
    //             catchError(error)
    //             if(tab){
    //                 handleTabClick(tab)
    //             }else{
    //                 handleTabClick(photos)
    //             }
    //         }
    //     }
    // }

    // const getAllCollectionData = () => {
    //     if(queryParams.get('tab')=='photos' && !(collectionData && collection_id in collectionData)){
    //         collectionApiCall(collection_id)
    //     }
    // }

    const fetchImages = async () => {
        var galleryImagesArr;
        if (faceSearchOn) {
            const galleryAll = faceSearchData?.photo_data?.flatMap((imageObj) => 
                imageObj.data.map((image) => ({
                  src: hdUrl ? image.download_url : image.view_url,
                  alt: image.identifier,
                  photo_id: image.photo_id
            })))
            const galleryPrivate = Object.values(faceHide).flatMap((imageObj) =>
                imageObj.map((image) => ({
                  src: hdUrl ? image.download_url : image.view_url,
                  alt: image.identifier,
                  photo_id: image.photo_id
                }))
              )
            if(selectedOption==privatePhotos){
                galleryImagesArr = galleryPrivate
            }else if(selectedOption==publicPhotos){
                galleryImagesArr = galleryAll?.filter(
                    image => !galleryPrivate.some(hiddenImage => hiddenImage.photo_id === image.photo_id)
                  );
            }else{
                galleryImagesArr = galleryAll
            }
            
        }else{
            const galleryAll = collectionData && collectionData[collection_id]?.data?.map((image) => ({
                src: hdUrl ? `${image.download_url}` : `${image.view_url}`,
                alt: `${image.identifier}`,
                photo_id: image.photo_id
            }));
            const galleryPrivate = hideSelectedObj[collection_id]?.map((image) => ({
                src: hdUrl ? `${image.download_url}` : `${image.view_url}`,
                alt: `${image.identifier}`,
                photo_id: image.photo_id
            }))
            if(selectedOption===privatePhotos){
                galleryImagesArr = galleryPrivate
            }else if(selectedOption == publicPhotos){
                galleryImagesArr = galleryAll?.filter(
                    image => !galleryPrivate.some(hiddenImage => hiddenImage.photo_id === image.photo_id)
                  );
            }
            else{
                galleryImagesArr = galleryAll
            }
        }
        setGalleryImages(galleryImagesArr);
        const img = new Image();
        img.src = !faceSearchOnly ? (hdUrl ? collection?.data[bid-1]?.download_url : collection?.data[bid-1]?.view_url) : (bid && galleryImagesArr[Number(bid-1)].src);
        img.onload = function() {
            setLoadingHD(false);
        };
    };

    // useEffect(()=>{
    //     getAllCollectionData()
    // },[collectionData, data])

    useEffect(()=>{
        updateSinglePhoto && updateSelectedPhotos()
    },[updateSinglePhoto])
    
    useEffect(() => {
        (tab==photos || faceSearchOnly) && fetchImages(); 
    }, [hdUrl, collection, selectedOption, faceSearchOn, faceSearchData]);
   
    useEffect(() => {
        var api_url 
        if(shared_key){
            if(faceSearchOnly){
                return;
            }
            api_url = `${process.env.REACT_APP_STAG_URL}/api/share/${shared_key}/`
        }else{
            api_url = `${process.env.REACT_APP_STAG_URL}/api/work/booking/${id}/`
        }
        axios({
          url: api_url,
          method: "get",
          headers: {
            Authorization: getAuthToken(),
          },
        })
          .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            catchError(error)
          });
    }, [id, shared_key]);

    useEffect(() => {
        if(tab && !collection_id && data){
            handleTabClick(tab)
        }
        else if((!tab || !collection_id) && data) {
            if (data.data['photo-collection']) {
                handleTabClick(photos);
            } else if (data.data['video']) {
                handleTabClick(videos);
            } else if (data.data['album']) {
                handleTabClick(albums);
            }
        }else if(tab && collection_id && data){
            handleCollections(collection_id)
        }
    }, [data]);

    useEffect(() => {
        if (pastFaceSearches && pastFaceSearches.length > 0) {
          pastFaceSearches.forEach((ele, index) => {
            cropImage(ele.img_url, ele.bounding_box, index);
          });
        }else{
            setLoading(false)
        }
    }, [pastFaceSearches]);

    useEffect(() => {
        setShowTooltip(true);
        const timer = setTimeout(() => {
          setShowTooltip(false);
        }, 10000);
    
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (filterDropdown || shareDropdown) {
          document.addEventListener("mousedown", handleClickOutside);
        } else {
          document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterDropdown, shareDropdown]);


    return (
        <>
            {isLoggedin && (data || faceSearchData) && !faceOnly ? 
                <>
                    <Navbar location={"/workDetails"} title={``}/>
                    {<Popup
                    isOpen={isPopupOpen}
                    onClose={() => {
                        setIsPopupOpen(false);
                    }}
                    >
                        <div className="downloadDetails">
                            {downloadDetails?.download_title &&<h3>{downloadDetails.download_title}</h3>}
                            <div className="downloadData" style={{display: !downloadDetails?.size_msg && 'none'}}>
                                The size of selected files is approx <span style={{ fontWeight: "900" }}>{downloadDetails?.size_msg}</span>.</div>
                            <Link to={`${downloadDetails?.link}`} target="_blank" style={{display:"flex", justifyContent:"center"}}>
                                <button className="shareWp" style={{backgroundColor:get_platform_bg_color(), border:'none'}}>
                                    <img style={{height: currentDimensions > 600 ? "22px" : "20px"}} src={downloadIcon} className="invert-image" alt="download"/>
                                Download Zip
                                </button>
                            </Link>
                            <div className="cpyAlbum" style={{backgroundColor: "rgba(0,0,0,0.7)", margin:"1rem 0 0"}}>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{ color: "#ffffff" }}
                                />
                                <p onClick={()=>copyLinkUrl(downloadDetails?.link)}>Copy Zip Link</p>
                            </div>
                            <Link to={`${getWhatsappShareUrl(downloadDetails?.link)}`} target="_blank">
                                <button className="shareWp">
                                    <FontAwesomeIcon
                                    icon={faWhatsapp}
                                    style={{ color: "#ffffff" }}
                                    size="lg"
                                    /> Share Zip Link
                                </button>
                            </Link>
                        </div>
                    </Popup>}
                    {updateLoader && <div className="overlayLoader">{globalLoader()}</div>}
                    <div>
                        {scrollPosition > 500 && <div className="scrollToTop" onClick={getToTop}><FontAwesomeIcon icon={faArrowUp} size="lg" style={{color: "#ffffff",}} /></div>}
                        {albumStatus!=='true' && videoStatus!=='true' && <div style={{backgroundColor: currentDimensions > 1200 && get_platform_bg_color(), display: !showFilters && currentDimensions > 1200 && 'none'}} className= {`${!selection ? (currentDimensions > 1200 ? 'topSwitchTabs' : 'bottomSwitchTabs') : 'displayNone'} ${selection ? 'inactiveClass' : ''}`}>
                            {data?.data && Object.keys(data?.data).map((key, index) => (
                                <React.Fragment key={index}>
                                    {key === 'photo-collection' && <p className={"tabsPara"} onClick={()=>handleTabClick(photos)} style={{borderBottom: tab==photos && (currentDimensions >1200 ? '3px solid white' : '3px solid black')}} key={index}>
                                            <img className={currentDimensions > 1200 ? "invert-image" : ""} src={imageIcon} style={{paddingRight : currentDimensions >600 && "5px"}}/>
                                            <span>Photos</span>
                                        </p>}
                                    {key === 'video' && <p className="tabsPara" onClick={()=>handleTabClick(videos)} style={{borderBottom: tab==videos&& (currentDimensions >1200 ? '3px solid white' : '3px solid black')}} key={index}>
                                            <img className={currentDimensions > 1200 ? "invert-image" : ""} src={videoIcon} style={{paddingRight : currentDimensions >600 && "5px"}}/>
                                            <span>Videos</span>
                                        </p>}
                                    {key === 'album' && <p className="tabsPara" onClick={()=>handleTabClick(albums)} style={{borderBottom: tab==albums&& (currentDimensions >1200 ? '3px solid white' : '3px solid black')}} key={index}>
                                            <img className={currentDimensions > 1200 ? "invert-image" : ""} src={albumIcon} style={{paddingRight : currentDimensions >600 && "5px"}}/>
                                            <span>Albums</span>
                                        </p>}
                                </React.Fragment>
                            ))}
                            {faceSearchOnly && <p className={"tabsPara"} style={{borderBottom: (currentDimensions >1200 ? '3px solid white' : '3px solid black')}}>
                                <img className={currentDimensions > 1200 ? "invert-image" : ""} src={imageIcon} style={{paddingRight : currentDimensions >600 && "5px"}}/>
                                <span>Photos</span>
                            </p>}
                        </div>}
                        {!faceSearchState ? <><div className={showFilters ? "filterOptions" : "hideFilters"} style={{paddingTop: !selection ? (currentDimensions <= 1200 ? (currentDimensions <= 600 ? "65px" : "75px") : "150px") : (currentDimensions <= 600 ? '55px' : '70px'), paddingBottom:"10px", backgroundColor: currentDimensions <= 1200 && get_platform_bg_color(), color: currentDimensions < 1200 && 'white'}}>
                            <div style={{display:"flex"}}><div className={selection ? 'displayNone' : "tooltip"}>{data?.can_face_search && tab==photos && <div className="faceFilterDiv" onClick={()=>{faceSearchOn ? exitFaceSearch() : faceFilterPhoto()}} style={{border: `${shared_key ? currentDimensions <1200 && '1px solid white' : 'none'}`, }}>
                                <img className={`${currentDimensions < 1200 ? "invert-image" : ""} ${(selection || faceSearchOn) && 'displayNone'}`} src={selfieIcon} style={{paddingRight: currentDimensions > 600 && "5px", width: currentDimensions > 600 ? "35px" : "30px", maxHeight:"30px"}}/>
                                {<p>
                                    {faceSearchOn ? (
                                        <span><FontAwesomeIcon icon={faArrowLeft} style={{color: currentDimensions > 1200 ? "#000000" : "#ffffff",}} /> Back</span>
                                    ) : (
                                        <span>{shared_key && 'My Photos'}</span>
                                    )}
                                </p>}
                            </div>}
                            {tab==photos && data?.can_face_search && <span className={`tooltiptext ${showTooltip ? 'show' : hide}`}>Use this to filter photos by your face</span>}</div>
                            {collectionData && collectionData[collection_id]?.can_make_private && <div id='hideButton' onClick={()=>getSelectionData(hide)} style={{display: (faceSearchOn && !faceSearchData?.can_make_private) || selection && "none"}}>
                                <img src={currentDimensions > 1200 ? hideIcon : whiteHideIcon}/>
                            </div>}
                            {tab==photos && ((!faceSearchOn ? (collectionData && collectionData[collection_id]?.is_downloadable && data?.selective_download) : (faceSearchData?.is_downloadable && faceSearchData?.selective_download)) || faceSearchOnly) && <div id='allDownloadButton' className={
                                faceSearchOn
                                ? (shared_key ? (faceSearchData?.public_photo_count>0 ? '' : 'displayNone') : ((getPhotoCountFaceSearch()>0) ? '' : 'displayNone'))
                                : (shared_key ? 'displayNone' : (images?.length>0 ? '' : 'displayNone'))
                            } onClick={downloadMethod} style={{display: (faceSearchOn && !faceSearchData?.is_downloadable) || selection && 'none'}}>
                                <img style={{height: currentDimensions > 600 ? "25px" : "20px", width: currentDimensions > 600 ? "25px" : "20px",}} src={downloadIcon} className={currentDimensions < 1200 ? "invert-image" : ""} alt="download"/>
                            </div>}</div>
                            <div className={selection ? 'displayNone' : ' '} style={{display:"flex", alignItems: "center"}}>
                                {collectionData && albumData && Object.keys(albumData).length>0 && <div className={
                                    faceSearchOn
                                    ? ((faceSearchData?.photo_data[0].can_add_to && faceSearchData?.photo_data[0].is_album_required) ? '' : "displayNone")
                                    : ((collection?.can_add_to && collection?.is_album_required) ? '' : 'displayNone')
                                    } onClick={()=>getSelectionData(addTo)} style={{marginRight: currentDimensions > 600 ? "25px" : "15px", border: currentDimensions > 1200 ? "1px solid black" : "1px solid white", padding: "5px", borderRadius: "1vh", cursor: "pointer", display: tab!==photos && 'none' }}>
                                    <FontAwesomeIcon icon={hollowCheck} style={{color: currentDimensions > 1200 ? '#000000' : "#ffffff"}}/> Select
                                </div>}
                                {data?.is_shareable && <div ref={shareRef} className="shareMediaDiv" onClick={()=>!contentLoading && shareMedia()} style={{display : faceSearchOn && 'none', background: currentDimensions > 1200 ? get_platform_bg_color() : 'white', color:currentDimensions > 1200 ? "white" : "black"}}><img className={currentDimensions < 1200 ? '' : "invert-image"} style={{width: currentDimensions < 1200 && '18px', marginRight:"7px"}} src={shareIcon} /> {" "}Share<>
                                    {shareDropdown && <div className="shareDropdown">
                                        <div className="shareOption" onClick={()=>{!isAdmin() && shareDeliverable('all')}}>Share Everything</div>
                                        <div className="shareOption" onClick={()=>shareDeliverable('selective')}>Share Selectively</div>
                                        <div className="shareOption" onClick={()=>{!isAdmin() && shareDeliverable('face_only')}}>Face Search Only</div>
                                </div>}</></div>}
                            </div>
                            {(selection) && <div style={{width:"inherit", display:"flex", justifyContent:"space-between"}}>
                                    <div>
                                        {(downloadSelection && !collection?.is_downloadable) || (hideSelection && !collection?.can_make_private) ? <></> : <button style={{border: currentDimensions < 1200 ? '1px solid white' : "1px solid black", padding:"5px 12px", borderRadius:"5px", marginRight:"10px", marginTop:"10px"}} onClick={()=>toggleSelection()}><FontAwesomeIcon icon={selectionAll[collection_id] ? faCircleCheck : hollowCheck} style={{color: currentDimensions < 1200 ? "#fff" : "#000000",}} /> Select all</button>}
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <button style={{border: currentDimensions < 1200 ? '1px solid white' : "1px solid black", padding:"5px 12px", borderRadius:"5px", marginRight:"10px", marginTop:"10px"}} onClick={()=>{!contentLoading && cancelSelection()}}>Discard</button>
                                        {selection && (hideSelection || downloadSelection) && <button id="hideDiv" style={{display: addToSelection && 'none', backgroundColor: get_platform_action_color()}} onClick={()=>{!contentLoading && updateSelectedPhotos()}}>Update</button>}
                                        {addToSelection && <button id="addToDiv" style={{backgroundColor: get_platform_action_color(), marginRight:"10px", marginTop:"10px"}} onClick={toggleAddToPopup}>Add To</button>}
                                        {selection && addToSelection && downloadSelection && <button id="downloadDiv" style={{marginRight:"10px", marginTop:"10px"}} onClick={updateSelectedPhotos}>
                                            <img style={{height: currentDimensions > 600 ? "25px" : "20px"}} src={downloadIcon} className={currentDimensions < 1200 ? "invert-image" : ""} alt="download"/>
                                        </button>}
                                        {<Popup
                                        isOpen={isAddToOpen}
                                        onClose={()=>setIsAddToOpen(false)}
                                        >
                                            <div
                                            ref={popupRef}
                                            style={{
                                                minWidth: currentDimensions > 600 ? "35vw" : "80vw",
                                                maxWidth: currentDimensions > 600 ? "35vw" : "80vw",
                                                padding: "20px",
                                                textAlign: "center",
                                                color: "black"
                                            }}
                                            >
                                            <h3 style={{ margin: "0 0 10px" }}>Add to Album</h3>
                                            <ul style={{ listStyle: "none", padding: 0 }}>
                                                {collectionData && collectionData[collection_id]?.album_data?.album_data && Object.entries(collectionData[collection_id].album_data['album_data']).map(([albumKey, myAlbumData]) => (
                                                    <li
                                                        key={albumKey}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            padding: "10px",
                                                            borderBottom: "1px solid #ddd",
                                                            cursor: "pointer",
                                                            marginRight:"10px"
                                                        }}
                                                    >
                                                    <a href={`${token_url(`${process.env.REACT_APP_STAG_URL}/draft-album?id=${albumKey}`)}`} target="_blank" size='xs' style={{marginRight: '10px'}}><FontAwesomeIcon style={{color: 'gray'}} icon={faExternalLinkAlt} /></a>
                                                    |
                                                    <label
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            width: "100%",
                                                            cursor: "pointer",
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                      <span>{myAlbumData.title}</span>
                                                
                                                      {albumData && !albumData[albumKey]?.is_locked ? <input
                                                            type="checkbox"
                                                            style={{
                                                                cursor: "pointer",
                                                                accentColor: get_platform_bg_color()
                                                            }}
                                                            checked={albumData && albumData[albumKey]?.is_checked || false}
                                                            onChange={(event) => {
                                                                const isChecked = event.target.checked;
                                                                updateAlbum(albumKey, isChecked)
                                                            }}
                                                            disabled={albumData && albumData[albumKey]?.is_locked || isAdmin()}
                                                      /> : <span style={{ fontStyle: 'italic', color: 'gray', fontSize: 'medium' }}>Locked</span>}
                                                    </label>
                                                  </li>
                                                ))}
                                            </ul>
                                            </div>
                                            <button
                                                style={{
                                                    marginRight: "2vw",
                                                    padding: "10px 20px",
                                                    backgroundColor: get_platform_action_color(),
                                                    border: "none",
                                                    borderRadius: "5px",
                                                    color: "white",
                                                    cursor: "pointer",
                                                }}
                                                onClick={addToDone}
                                            >
                                                Done
                                            </button>
                                        </Popup>}
                                        
                                    </div>
                                </div>}
                        </div>
                        {(tab==photos || faceSearchOnly) && <div style={{display: "flex", justifyContent:"center", alignItems:"center", paddingTop:currentDimensions < 1200 || selection ? "130px" : "220px"}}>
                            {showArrow && currentDimensions>1200 && !faceSearchOn && <div id='leftArrow' style={{cursor:"pointer"}} onClick={()=>scrollStory('left')}><FontAwesomeIcon icon={faChevronLeft} size="lg" style={{color: "#000000", padding:"0 10px"}} /></div>}
                            <div id='storyNav' ref={storyRef} style={{display: "flex", overflow: "auto"}}>{data?.data['photo-collection'] && data.data['photo-collection'].map((e, index)=>(
                                <div onClick={()=>handleCollections(e.work_id)} key={index} className={currentDimensions < 1200 ? `storyDiv ${contentLoading && 'inactiveClass'}` : `pillStoryDiv ${contentLoading && 'inactiveClass'}`} style={{background: e.work_id == collection_id && currentDimensions > 1200 && get_platform_bg_color(), color: e.work_id == collection_id && currentDimensions > 1200 && 'white', boxShadow: e.work_id == collection_id && currentDimensions > 1200 && "0 -2px 10px rgba(0, 0, 0, 0.4)", display: faceSearchData?.photo_data && 'none', margin: `20px ${pillMargin}px`}}>
                                    <img className={currentDimensions < 1200 ? "imgStory" : 'pillImgStory'} style={{border: e.work_id == collection_id && currentDimensions <1200 && `3px solid ${get_platform_bg_color()}`}} src={e?.cover_image}/>
                                    <div style={{display:"flex", flexDirection:"column"}}>{splitTitle(e.title).map((e)=>{
                                        return (
                                            <span style={{whiteSpace:"nowrap"}}>{e.length>11 ? e.slice(0,8)+'...' : e}</span>
                                        )
                                    })}</div>
                                </div>
                            ))}</div>
                            {faceSearchData?.photo_data && <div style={{width:"100%", display:"flex", justifyContent:"left", padding:"10px 20px"}}>
                                <button className='retrySearch' style={{display: selection && 'none'}} onClick={()=>faceSearch()}><FontAwesomeIcon icon={faRotateRight} style={{color: "#000000"}} /> Retake</button>
                            </div>}
                            {showArrow && !faceSearchOn && currentDimensions>1200 &&  <div id='rightArrow' onClick={()=>scrollStory('right')} style={{cursor:"pointer"}}><FontAwesomeIcon icon={faChevronRight} size="lg" style={{color: "#000000", padding:"0 10px"}} /></div>}
                        </div>}
                        {tab==albums && albumStatus!=='true' && <div style={{height: data.data['album'].length == 1 && '90vh', gridTemplateColumns: data.data['album'].length == 1 && 'repeat(1, 1fr)'}} className="albumGrid">
                            {data.data['album'] && data.data['album'].map((e, index)=>(
                                <div key={index} id={e.work_id}  className="albumStoryDiv" onClick={()=>handleAlbum(e.work_id)}>
                                    <img style={{objectFit: "cover", height: "75%", width:"100%", borderRadius:"2%"}} src={e.cover_image} />
                                    <span style={{fontSize:currentDimensions>600 ? "1.3rem" : "1.1rem", height:"25%", display:"flex", alignItems:"center"}}>{e.title.length>50 ? e.title.slice(0,49)+'...' : e.title} {" "} </span>
                                </div>
                            ))}
                        </div>}
                        {tab==videos && videoStatus!=='true' && <div style={{height: data.data['video'].length == 1 && '90vh', gridTemplateColumns: data.data['video'].length == 1 && 'repeat(1, 1fr)'}} className="videoGrid">
                            {data.data['video'] && data.data['video'].map((e, index)=>(
                                <div key={index} id={e.work_id} className="videoStoryDiv" onClick={()=>handleVideo(e.work_id)}>
                                    <div style={{position:"absolute", top:"40%", left:"50%"}}><FontAwesomeIcon icon={faPlay} style={{color: "#ffffff",}} size="xl"/></div>
                                    <img style={{objectFit: "cover", height: "75%", width:"100%", borderRadius:"2%"}} src={e.thumb_url}/>
                                    <div style={{display:"flex", alignItems:"center", justifyContent:e.is_downloadable ? "space-between" : 'center', width:"90%", height:"25%"}}><span style={{fontSize: currentDimensions>600 ? "1.2rem" : "18px", marginRight:"5px"}}>{e.title.length>50 ? e.title.slice(0,49)+'...' : e.title} {" "} </span><span>
                                        {e.is_downloadable && <div onClick={(event)=>{event.stopPropagation();qualityPopup(e.url_list)}} className="cardDownload">Download</div>}
                                    </span></div>
                                </div>
                            ))} 
                        </div>}
                        {(tab==photos || faceSearchOnly) && <div ref={divRef} style={{fontSize: "large", fontWeight: "600", padding: "0 2% 10px 2%", display:"flex", justifyContent: shared_key ? "center" : "space-between"}}>
                            {<div style={{width: currentDimensions>900 ? '125px' : '85px', display: shared_key && 'none'}}></div>}
                            <div style={{maxWidth:currentDimensions < 600 && "70%", fontSize:currentDimensions < 600 && '15px', display:"flex", alignItems:"center"}}>{!faceSearchData?.photo_data && collection?.title}{faceSearchData?.photo_data && images && `${faceSearchData?.photo_count} Photos Matched`}</div>
                            {!shared_key && <div ref={dropdownRef} style={{display:"flex"}}>
                                <div 
                                className={`${contentLoading ? 'inactiveClass' : ''}`} 
                                style={{
                                    display: 'flex', 
                                    alignItems: "center", 
                                    position: "relative", 
                                    cursor: "pointer", 
                                    padding: "0 10px", 
                                    visibility: selection && 'hidden', 
                                    fontSize: "18px", 
                                    fontWeight: "normal", 
                                }} 
                                onClick={(event) => {
                                    setFilterDropdown((prev) => !prev);
                                }}
                                >
                                <img style={{ height: "25px" }} src={filter} alt="Filter Icon" />
                                <p style={{ display: currentDimensions < 900 && 'none', marginLeft: '5px' }}>Filter</p>
                                {!faceSearchData?.photo_data ? 
                                    (collection && `(${selectedOption == allPhotos ? collection?.total_count : images?.length})`) : 
                                    `(${getPhotoCountFaceSearch()})`}
                                {selectedOption !== allPhotos && 
                                    <sup>
                                    <div className="dot" style={{ backgroundColor: get_platform_bg_color() }}></div>
                                    </sup>}
                                </div>
                                {filterDropdown && (
                                <div
                                    style={{
                                    position: "absolute",
                                    top: `${distanceFromTop}px`,
                                    right: "10px",
                                    border: "1px solid #ccc",
                                    backgroundColor: "#fff",
                                    padding: "10px",
                                    borderRadius: "4px",
                                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                                    zIndex: 1,
                                    width: "max-content",
                                    fontWeight: "100"
                                    }}
                                >
                                    <label htmlFor="all" style={{ display: "flex", marginBottom: "5px", justifyContent: "flex-start", alignItems:"baseline" }}>
                                    <input
                                        id="all"
                                        type="checkbox"
                                        checked={selectedOption == allPhotos}
                                        onChange={() => handleFilterCheckboxChange(allPhotos)}
                                        style={{ marginRight: "10px", accentColor: get_platform_bg_color() }}
                                    />
                                    All Photos{` (${faceSearchOn ? faceSearchData?.photo_count : collection?.total_count})`}
                                    </label>
                                    <hr />
                                    <label htmlFor="private" style={{ display: "flex", marginBottom: "5px", justifyContent: "flex-start", alignItems:"baseline" }}>
                                    <input
                                        id="private"
                                        type="checkbox"
                                        checked={selectedOption == privatePhotos}
                                        onChange={() => handleFilterCheckboxChange(privatePhotos)}
                                        style={{ marginRight: "10px", accentColor: get_platform_bg_color() }}
                                    />
                                    Hidden{hideSelectedObj && ` (${faceSearchOn ? Object.values(faceHide).reduce((sum, arr) => sum + arr.length, 0) : hideSelectedObj[collection_id]?.length})`}
                                    </label>
                                    <hr />
                                    <label htmlFor="public" style={{ display: "flex", marginBottom: "5px", justifyContent: "flex-start", alignItems:"baseline" }}>
                                    <input
                                        id="public"
                                        type="checkbox"
                                        checked={selectedOption == publicPhotos}
                                        onChange={() => handleFilterCheckboxChange(publicPhotos)}
                                        style={{ marginRight: "10px", accentColor: get_platform_bg_color() }}
                                    />
                                    Public{` (${faceSearchOn ? (Number(faceSearchData?.photo_count) - Number(Object.values(faceHide).reduce((sum, arr) => sum + arr.length, 0))) : collection?.total_count - hideSelectedObj[collection_id]?.length})`}
                                    </label>
                                </div>
                                )}
                            </div>}
                        </div>}
                        {
                            (tab==videos && videoStatus=='true') && <div>
                                <div style={{position:"absolute", top:"20px", left:"20px", color:"white", zIndex:"201"}} onClick={()=>closeVideo()}>
                                    <FontAwesomeIcon icon={faXmark} size="xl" style={{color: "#ffffff",cursor:"pointer"}}/>
                                </div>
                                {(collection && videoUrl) ?
                                <div style={{position: "absolute", width: "100%", top: "0", zIndex: "200"}}><VideoPlayer data={collection} bookingPK={bookingPK} id={collection_id} videoUrl={videoUrl} /></div> :
                                <div className='overlayLoader' style={{background:"rgba(0,0,0,1)"}}>{globalLoader()}</div>
                            }
                            </div>
                        }
                        {(tab==photos) && !faceSearchData?.photo_data && <div>
                            {(images) ?
                            <div style={{paddingBottom:currentDimensions < 600 ? "25%" : '10%', display: shareFaceFilter && shared_key && "none", pointerEvents: selection && (!collection?.is_downloadable || !collection?.can_make_private) && 'none'}}>
                                {images?.length > 0 ? <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                                >
                                {images?.map((e, index) => (
                                    <a key={index} href={`#gallery?index=${index + 1}`}>
                                    <div
                                        id={`gallery${index + 1}`}
                                        style={{ position: "relative" }}
                                        onClick={(event) => {
                                        if (selection) {
                                            event.preventDefault();
                                            toggleSelectImage(e);
                                        }
                                        }}
                                    >
                                        <img
                                        id={`${index + 1}`}
                                        style={{
                                            display: "block",
                                            width: "100%",
                                            padding: "2.5px",
                                        }}
                                        key={index}
                                        src={e.thumb_url}
                                        onClick={galleryHandler}
                                        ></img>
                                        {!(downloadSelection || addToSelection) ? (hideSelection || selectedImages[collection_id]?.some(image => image.photo_id === images?.[index].photo_id)) && (
                                        <div style={{position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: hideSelection && "100%",
                                                    height: hideSelection && "100%",
                                                    backgroundColor: hideSelection && "rgba(0,0,0,0.5)",
                                                    zIndex: hideSelection && 1,
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    justifyContent: "flex-start",
                                                    transition: "opacity 0.3s ease"}}>
                                            {selectedImages[collection_id]?.some(el => el.photo_id === images?.[index].photo_id) ? (
                                            <img style={{ width: "30px", margin:"20px",pointerEvents: hideSelection && 'none', filter:"drop-shadow(0px 0px 6px #fff)"}} src="https://assets.graphia.in/site_media/webapp/red_eye.png" />
                                            ) : (
                                            <img style={{ width: "30px", margin:"20px",pointerEvents: hideSelection && 'none', filter:"drop-shadow(0px 0px 6px #fff)"}} src="https://assets.graphia.in/site_media/webapp/white_eye.png" />
                                            )}
                                        </div>
                                        ) :
                                        <div style={{position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: (downloadSelection || addToSelection) && "100%",
                                                    height: (downloadSelection || addToSelection) && "100%",
                                                    backgroundColor: (downloadSelection || addToSelection) && "rgba(0,0,0,0.5)",
                                                    zIndex: (downloadSelection || addToSelection) && 1,
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    justifyContent: "flex-start",
                                                    transition: "opacity 0.3s ease"}}>
                                            {selectImages && selectImages[collection_id]?.some(el => el.photo_id === images?.[index].photo_id) ? (
                                                <FontAwesomeIcon size='lg' icon={faCircleCheck} style={{color: get_platform_action_color(), margin:"20px",pointerEvents: downloadSelection && 'none', backgroundColor:"white", borderRadius:"50%"}} />
                                            ) : (
                                                <FontAwesomeIcon size='lg' icon={hollowCheck} style={{color: "#ffffff", margin:"20px",pointerEvents: downloadSelection && 'none'}} />
                                            )}
                                        </div>}
                                    </div>
                                    </a>
                                ))}
                                </Masonry> :
                                <div className="noPhotoDiv">
                                <img
                                  style={{height:"90%"}}
                                  className="noPhoto"
                                  src={noPhotoDog}
                                  alt="No Photos Available"
                                />
                              </div>}
                                {downloadSelection && !collection?.is_downloadable && photoScroll==0 && <div className="noDownload" style={{top: `${distanceFromTop}px`}}>
                                        <div style={{background:"white", padding:"5px 12px", borderRadius:"10px", maxWidth:"70%"}}><FontAwesomeIcon icon={faCircleInfo} style={{color: get_platform_bg_color(),}} /> Collection is not downloadable!</div>
                                    </div>}
                                {hideSelection && !collection?.can_make_private && <div className="noHidden">
                                    <div style={{background:"white", padding:"5px 12px", borderRadius:"10px", maxWidth:"70%"}}><FontAwesomeIcon icon={faCircleInfo} style={{color: get_platform_bg_color(),}} /> Collection cannot be hidden!</div>
                                </div>}
                            {loading && <div style={{padding:"5%"}}>{globalLoader()}</div>}
                            {nextCollection && !loading && nextCollectionName && <div className="nextCollectionDiv" style={{paddingBottom: getMobileOperatingSystem()=="iOS" && '35%'}}><button className="nextCollection" onClick={handleNextCollection}>{nextCollectionName} <FontAwesomeIcon icon={faArrowRight} style={{color: "#ffffff",}} size="sm"/></button></div>}</div> :
                            <div className="skeleton-grid" style={{display:queryParams.get('tab')!==photos && "none"}}>
                                {[...Array(24)].map((_, index) => (
                                <div key={index} className="skeleton-item">
                                    <Skeleton height={350} />
                                </div>
                                ))}
                            </div>}
                            </div>
                        }
                        {(tab==photos || faceSearchOnly) && faceSearchData?.photo_data ? <div>
                            <div style={{paddingBottom:currentDimensions < 600 ? "35%" : '10%'}}>
                            {faceSearchData?.photo_count > 0 ? (
                                faceSearchData?.photo_data.map((ele, index) => (
                                    <div key={index}>
                                        {getDataByOption(ele, selectedOption).length > 0 && (
                                            <div className="searchCollectionTitle">
                                                {ele.title}
                                            </div>
                                        )}
                                    <Masonry
                                    key={index}
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column"
                                    >
                                    {getDataByOption(ele, selectedOption)?.map((e, idx) => {
                                        currentIndex++
                                        return (
                                            <a key={currentIndex} href={`#gallery?index=${currentIndex}`}>
                                        <div
                                            id={`gallery${currentIndex}`}
                                            style={{ position: "relative" }}
                                            onClick={(event) => {
                                            if (selection) {
                                                event.preventDefault();
                                                toggleSelectImage(e);
                                            }
                                            }}
                                        >
                                            <img
                                            id={`${currentIndex}`}
                                            style={{
                                                display: "block",
                                                width: "100%",
                                                padding: "2.5px",
                                            }}
                                            src={e.thumb_url}
                                            onClick={galleryHandler}
                                            alt={`Thumbnail ${currentIndex}`}
                                            />
                                            {!(downloadSelection || addToSelection) ? (hideSelection || faceHide[ele.work_id].some(el =>el.photo_id == e.photo_id)) && (
                                            <div
                                                style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: hideSelection ? "100%" : undefined,
                                                height: hideSelection ? "100%" : undefined,
                                                backgroundColor: hideSelection ? "rgba(0,0,0,0.5)" : undefined,
                                                zIndex: hideSelection ? 1 : undefined,
                                                display: "flex",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                transition: "opacity 0.3s ease",
                                                }}
                                            >
                                                {faceSelect[ele.work_id].some(el =>el.photo_id == e.photo_id) ? (
                                                <img
                                                    style={{
                                                    width: "30px",
                                                    margin: "20px",
                                                    pointerEvents: hideSelection ? 'none' : undefined,
                                                    filter: "drop-shadow(0px 0px 6px #fff)",
                                                    }}
                                                    src="https://assets.graphia.in/site_media/webapp/red_eye.png"
                                                    alt="Selected"
                                                />
                                                ) : (
                                                <img
                                                    style={{
                                                    width: "30px",
                                                    margin: "20px",
                                                    pointerEvents: hideSelection ? 'none' : undefined,
                                                    filter: "drop-shadow(0px 0px 6px #fff)",
                                                    }}
                                                    src="https://assets.graphia.in/site_media/webapp/white_eye.png"
                                                    alt="Not Selected"
                                                />
                                                )}
                                            </div>
                                            ) : <div
                                            style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: downloadSelection || addToSelection ? "100%" : undefined,
                                            height: downloadSelection || addToSelection ? "100%" : undefined,
                                            backgroundColor: downloadSelection || addToSelection ? "rgba(0,0,0,0.5)" : undefined,
                                            zIndex: downloadSelection || addToSelection ? 1 : undefined,
                                            display: "flex",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            transition: "opacity 0.3s ease",
                                            }}
                                        >
                                            {selectImages && selectImages[ele.work_id]?.some(el =>el.photo_id == e.photo_id) ? (
                                                <FontAwesomeIcon size='lg' icon={faCircleCheck} style={{color: get_platform_action_color(), margin:"20px",pointerEvents: downloadSelection && 'none', backgroundColor:"white", borderRadius:"50%"}} />
                                            ) : (
                                                <FontAwesomeIcon size='lg' icon={hollowCheck} style={{color: "#ffffff", margin:"20px",pointerEvents: downloadSelection && 'none'}} />
                                            )}
                                        </div>}
                                        </div>
                                        </a>
                                        )
                                    })}
                                    </Masonry></div>
                                ))
                                ) : (
                                <div className="noPhotoDiv">
                                    <img
                                    style={{height:"90%"}}
                                    className="noPhoto"
                                    src={noPhotoDog}
                                    alt="No Photos Available"
                                    />
                                </div>
                            )}
                            {( 
                                (selectedOption === privatePhotos && faceSearchData?.private_photo_count === 0) || 
                                (selectedOption === publicPhotos && faceSearchData?.public_photo_count === 0)
                            ) && (
                                <div className="noPhotoDiv">
                                    <img
                                        style={{height:"90%"}}
                                        className="noPhoto"
                                        src={noPhotoDog}
                                        alt="No Photos Available"
                                    />
                                </div>
                            )}
                            
                            </div>
                        </div> :
                        <div className="skeleton-grid" style={{display: (!faceSearchOn || queryParams.get('tab')!==photos) && 'none'}}>
                            {[...Array(24)].map((_, index) => (
                            <div key={index} className="skeleton-item">
                                <Skeleton height={350} />
                            </div>
                            ))}
                        </div>
                        }
                        {
                            (tab==albums) && albumStatus=='true' && <div>
                                <div style={{position:"absolute", top:"10px", left:"5px", color:"white", zIndex:"100"}} onClick={()=>closeAlbum()}>
                                    <FontAwesomeIcon icon={faXmark} size="xl" style={{color: "#ffffff",cursor:"pointer"}} />
                                </div>
                                {(collection && collection.data) ?
                                <div style={{position: "absolute", width: "100%", top: "0", zIndex: "50"}}><AlbumView id={collection_id}/></div> :
                                <div className='overlayLoader' style={{background: "rgba(0,0,0,1)"}}>{globalLoader()}</div>
                            }
                            </div>
                        }</> : <FaceSearch updateFaceSearchResult={updateFaceSearchResult}/>}
                        {shareFaceFilter && shared_key && tab==photos && data?.data['photo-collection'] && <div className="shareFirstPage">
                                <div style={{width:"inherit", height:"inherit", backgroundColor:"#FBF7F4", paddingTop:currentDimensions > 600 ? "20vh" : "15vh"}}>
                                    <div style={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
                                        <div className="shareFaceDiv">
                                            <img style={{width:"200px", paddingBottom:"30px"}} src="https://assets.graphia.in/site_media/webapp/selfie_emoji.png" alt="faceSearch"/>
                                            <div className="faceFilterDiv" style={{border:`1px solid ${get_platform_bg_color()}`, backgroundColor: get_platform_bg_color(), cursor:"pointer", marginRight: shared_key && '0px'}} onClick={()=>{faceFilterPhoto()}}>
                                                <img className="invert-image" src={selfieIcon} style={{paddingRight: "5px", width: "35px"}}/>
                                                <span style={{color:"white", fontSize:currentDimensions < 600 && "17px"}}>My Photos</span>
                                            </div>
                                            <div style={{paddingTop:"30px", fontSize: currentDimensions > 600 ? "large" : '20px', maxHeight:"80%"}}>Take a selfie to view your photos.</div>
                                        </div>
                                        <div style={{fontSize:currentDimensions > 600 ? "large" : '20px', fontWeight:"900", padding: currentDimensions > 600 ? "20px" : '10px'}}>----- OR -----</div>
                                        <div className="allPhotosShare" onClick={()=>exitFaceSearch()}>View All Photos</div>
                                    </div>
                                </div>
                            </div>}
                        
                        
                    </div>
                    {showGallery && galleryImages && bid >= 0 && (
                        <div  ref={thumbRef}
                        onClick={handlePlayClick}
                        style={{
                            position: "fixed",
                            zIndex: "200",
                            top: "0",
                            left: "0",
                            height: "100vh",
                            width: "100vw",
                        }}
                        >
                            <Carousel
                                ref={carouselRef}
                                index={bid - 1}
                                images={galleryImages}
                                hasCaptions={false}
                                onIndexChange={handleIndexChange}
                                hasThumbnails={false}
                                canAutoPlay={currentDimensions > 600 ? true : false}
                                hasLeftButton={false}
                                hasRightButton={false}
                                elements={
                                    <>
                                    <button
                                        style={{
                                        position: 'absolute',
                                        top: '42%',
                                        left: 0,
                                        transform: 'translateY(-50%)',
                                        borderRadius: 0
                                        }}
                                        aria-label='Go to the slide on the left'
                                        onClick={() => carouselRef.current.goLeft()}
                                    >
                                        <span style={{
                                            fontSize: 'min(30px, 5vw)', paddingLeft:"2vw"
                                        }}>
                                            <FontAwesomeIcon icon={faArrowLeft} style={{color: "#ffffff",}} />
                                        </span>
                                    </button>
                                    <button
                                        style={{
                                        position: 'absolute',
                                        top: '42%',
                                        right: 0,
                                        transform: 'translateY(-50%)',
                                        borderRadius: 0
                                        }}
                                        aria-label='Go to the slide on the right'
                                        onClick={() => carouselRef.current.goRight()}
                                    >
                                        <span
                                        style={{
                                            fontSize: 'min(30px, 5vw)', paddingRight:"2vw"
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faArrowRight} style={{color: "#ffffff",}} />
                                        </span>
                                    </button>
                                    </>
                                }
                            />
                            {
                                loadingHD && <div className='overlayLoader'>{globalLoader()}</div>
                            }
                            {<Popup
                            isOpen={isSingleAddToOpen}
                            onClose={() => {setIsSingleAddToOpen(false); setAddToSelection(false)}}
                            >
                                <div
                                ref={popupRef}
                                style={{
                                    minWidth: currentDimensions > 600 ? "40vw" : "80vw",
                                    padding: "20px",
                                    textAlign: "center",
                                    color: "black"
                                }}
                                >
                                <h3 style={{ margin: "0 0 10px" }}>Add to Album</h3>
                                <ul style={{ listStyle: "none", padding: 0 }}>
                                    {collectionData && collectionData[collection_id]?.album_data?.album_data && Object.entries(collectionData[collection_id].album_data['album_data']).map(([albumKey, myAlbumData]) => (
                                        <li
                                            key={albumKey}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                padding: "10px",
                                                borderBottom: "1px solid #ddd",
                                                cursor: "pointer",
                                            }}
                                        >
                                        <a href={`${token_url(`${process.env.REACT_APP_STAG_URL}/draft-album?id=${albumKey}`)}`} target="_blank" size='xs' style={{marginRight: '10px'}}><FontAwesomeIcon style={{color: 'gray'}} icon={faExternalLinkAlt} /></a>
                                        |
                                        <label
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                width: "100%",
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            <span>{myAlbumData.title}</span>

                                            {albumData && !albumData[albumKey]?.is_locked ? <input
                                                type="checkbox"
                                                style={{
                                                    cursor: "pointer",
                                                    accentColor: get_platform_bg_color()
                                                }}
                                                checked={checkAlbumKey(galleryImages[bid - 1].photo_id, albumKey) || false}
                                                onChange={(event) => {
                                                    const isChecked = event.target.checked;
                                                    updateAlbum(albumKey, isChecked, collection_id, galleryImages[bid - 1].photo_id)
                                                }}
                                                disabled={albumData && albumData[albumKey]?.is_locked || isAdmin()}
                                            /> : <span style={{ fontStyle: 'italic', color: 'gray', fontSize: 'medium' }}>Locked</span>}
                                        </label>
                                        </li>
                                    ))}
                                </ul>
                                </div>
                                <button
                                    style={{
                                        marginRight: "2vw",
                                        padding: "10px 20px",
                                        backgroundColor: get_platform_action_color(),
                                        border: "none",
                                        borderRadius: "5px",
                                        color: "white",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {setIsSingleAddToOpen(false); setAddToSelection(false)}}
                                >
                                    Done
                                </button>
                            </Popup>}
                            <div className={currentDimensions > 600 ? 'topControls' : `${faceSearchOn ? ((faceSearchData?.photo_data[0].can_add_to && faceSearchData?.photo_data[0].is_album_required) ? 'bottomControls' : 'centerControls') : ((collection?.can_add_to && collection?.is_album_required) ? 'bottomControls' : 'centerControls')}`}>
                                {(faceSearchOn ? (faceSearchData?.photo_data[0].can_add_to && faceSearchData?.photo_data[0].is_album_required) : (collection?.can_add_to && collection?.is_album_required)) && <><div className={`${faceSearchOn ? ((faceSearchData?.photo_data[0].can_add_to && faceSearchData?.photo_data[0].is_album_required) && currentDimensions < 600 ? 'leftCenterControls' : 'topCenterControls') : ((collection?.can_add_to && collection?.is_album_required) && currentDimensions < 600 ? 'leftCenterControls' : 'topCenterControls')}`}>
                                    <div className={
                                            faceSearchOn
                                                ? (faceSearchData?.is_downloadable ? (hdUrl ? 'activeHD' : 'hdButton') : 'displayNone')
                                                : (collection?.is_downloadable ? (hdUrl ? 'activeHD' : 'hdButton') : 'displayNone')
                                        } onClick={switchHD}>
                                        <p>HD</p>
                                    </div>
                                    <div className={
                                        faceSearchOn 
                                            ? (faceSearchData?.can_make_private ? 'galleryHide' : 'displayNone')
                                            : (collection?.can_make_private ? 'galleryHide' : 'displayNone')
                                    } onClick={()=>handlePhotoHide(galleryImages[bid-1]?.photo_id)}>
                                        {faceSearchOn ? (
                                            Object.keys(faceHide).some((key) =>
                                                faceHide[key].some(e => e.photo_id === galleryImages[bid - 1]?.photo_id)
                                            ) ? (
                                                <img style={{ width: "24px" }} src={redHideIcon} />
                                            ) : (
                                                <img style={{ width: "24px" }} src={whiteHideIcon} />
                                            )
                                            ) : (
                                            collection?.private_data.some(e => e.photo_id === galleryImages[bid - 1]?.photo_id) ? (
                                                <img style={{ width: "24px" }} src={redHideIcon} />
                                            ) : (
                                                <img style={{ width: "24px" }} src={whiteHideIcon} />
                                            )
                                        )}
                                    </div>
                                </div>
                                {(!faceSearchOn ? (collection?.can_add_to && collection?.is_album_required) : (faceSearchData?.photo_data[0].can_add_to && faceSearchData?.photo_data[0].is_album_required)) && (collection?.can_add_to && collection?.is_album_required) && <div className={
                                        faceSearchOn
                                            ? (!shared_key ? 'galleryAddto' : "displayNone")
                                            : (!shared_key ? 'galleryAddto' : 'displayNone')
                                } onClick={toggleSingleAddToPopup}>
                                    <FontAwesomeIcon icon={faPlus} style={{color: "#000000"}}/> Add to
                                </div>}                                
                                <div className={`${faceSearchOn ? ((faceSearchData?.photo_data[0].can_add_to && faceSearchData?.photo_data[0].is_album_required) && currentDimensions < 600 ? 'rightCenterControls' : 'topCenterControls') : ((collection?.can_add_to && collection?.is_album_required) && currentDimensions < 600 ? 'rightCenterControls' : 'topCenterControls')}`}>
                                    <div
                                        className={
                                            faceSearchOn
                                                ? (faceSearchData?.is_downloadable ? 'downloadButton' : 'displayNone')
                                                : (collection?.is_downloadable ? 'downloadButton' : 'displayNone')
                                        }
                                        onClick={()=>downloadImage(this)}
                                    >
                                        <img style={{height: "20px"}} src={downloadIcon} className="coloredImage" alt="download"/>
                                    </div>
                                    <div className="likeDiv" style={{display:"none"}}>
                                        <img className="invert-image" style={{width: "22px"}} src={heartIcon}/>
                                    </div>
                                </div></>}
                                {(faceSearchOn ? (!faceSearchData?.photo_data[0].can_add_to && !faceSearchData?.photo_data[0].is_album_required) : (!collection?.can_add_to && !collection?.is_album_required)) && <>
                                    <div className={
                                            faceSearchOn
                                                ? (faceSearchData?.is_downloadable ? (hdUrl ? 'activeHD' : 'hdButton') : 'displayNone')
                                                : (collection?.is_downloadable ? (hdUrl ? 'activeHD' : 'hdButton') : 'displayNone')
                                        } onClick={switchHD}>
                                        <p>HD</p>
                                    </div>
                                    <div className={
                                        faceSearchOn 
                                            ? (faceSearchData?.can_make_private ? 'galleryHide' : 'displayNone')
                                            : (collection?.can_make_private ? 'galleryHide' : 'displayNone')
                                    } onClick={()=>handlePhotoHide(galleryImages[bid-1]?.photo_id)}>
                                        {faceSearchOn ? (
                                            Object.keys(faceHide).some((key) =>
                                                faceHide[key].some(e => e.photo_id === galleryImages[bid - 1]?.photo_id)
                                            ) ? (
                                                <img style={{ width: "24px" }} src={redHideIcon} />
                                            ) : (
                                                <img style={{ width: "24px" }} src={whiteHideIcon} />
                                            )
                                            ) : (
                                            collection?.private_data.some(e => e.photo_id === galleryImages[bid - 1]?.photo_id) ? (
                                                <img style={{ width: "24px" }} src={redHideIcon} />
                                            ) : (
                                                <img style={{ width: "24px" }} src={whiteHideIcon} />
                                            )
                                        )}
                                    </div>
                                    {faceSearchOn && (faceSearchData?.photo_data[0].can_add_to && faceSearchData?.photo_data[0].is_album_required) && <div className={
                                            faceSearchOn
                                                ? (!shared_key ? 'galleryAddto' : "displayNone")
                                                : (!shared_key ? 'galleryAddto' : 'displayNone')
                                    } onClick={toggleSingleAddToPopup}>
                                        <FontAwesomeIcon icon={faPlus} style={{color: "#000000"}}/> Add to
                                    </div>}
                                    <div
                                        className={
                                            faceSearchOn
                                                ? (faceSearchData?.is_downloadable ? 'downloadButton' : 'displayNone')
                                                : (collection?.is_downloadable ? 'downloadButton' : 'displayNone')
                                        }
                                        onClick={()=>downloadImage(this)}
                                    >
                                        <img style={{height: "20px"}} src={downloadIcon} className="coloredImage" alt="download"/>
                                    </div>
                                    <div className="likeDiv" style={{display:"none"}}>
                                        <img className="invert-image" style={{width: "22px"}} src={heartIcon}/>
                                    </div>
                                </>}
                            </div>
                            <div
                            style={{ position: "absolute", top: "11px", left: "20px" }}
                            onClick={closeGalleryHandler}
                                >
                                    <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    size="xl"
                                    className="closeEffect"
                                    />
                            </div>
                            <div className="photoName">
                                <p>{galleryImages[bid-1]?.alt}</p>
                            </div>
                        </div>
                    )}
                    <div id="hidePopupDiv">
                        <div id="hidePhotoPopup">
                            <h1><span id="popupTitle">Hide</span> this Photo?</h1>
                            <h2 id="popupDesc">Hidden photos won't be visible to everyone(except Admin)</h2>
                            <h3>Note</h3>
                            <li>Only Admin can hide/unhide photos</li>
                            <div id="hideButtonDiv">
                                <button id="hideButtonGallery" onClick={()=>handleHideImage(galleryImages[bid-1]?.photo_id)} style={{backgroundColor: get_platform_bg_color()}}>Hide</button>
                                <button id="cancelHideButton" onClick={cancelHide}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div id="grideHidePopup">
                        <div id="gridHidePopup">
                            <h3>Hide Photos</h3>
                            <li>Click on <span style={{verticalAlign:"sub"}}><img style={{width:"20px", display:"inline-block", verticalAlign:"center"}} src={hideIcon}/></span> to hide/show photos</li>
                            <li>Hidden photos won't be visible to everyone(except Admin)</li>
                            <li>Only Admin can hide/unhide photos</li>
                            <div id="grideHideButtonDiv">
                                <button style={{backgroundColor: get_platform_action_color(), 
                                                color: "#ffffff", 
                                                border: `1px solid ${get_platform_action_color()}`, 
                                                padding:"5px 12px",
                                                borderRadius:"5px"}} onClick={okayHide}>Okay</button>
                            </div>
                        </div>
                    </div>
                    {showToast && <Toast message={toastMessage} duration={3000} onClose={() => setShowToast(false)}/>}
                    {faceFilter && <div className="filterBackdrop">
                            <div className={currentDimensions < 600 ? "drawer open" : "facePopup"}>
                                <div>
                                    <p style={{position:"absolute", top:"10px", right:"20px", cursor:"pointer"}} onClick={closeFacePopup}><FontAwesomeIcon icon={faXmark} size="lg"/></p>
                                    <div style={{fontWeight :"900", paddingBottom:"30px"}}>Past face searches</div>
                                    <div style={{display:"flex", alignItems:"center", width:"100%", justifyContent:"space-around"}}>
                                    {isScrollable && <p onClick={scrollLeft}><FontAwesomeIcon icon={faAngleLeft} size="lg" style={{color: "#000000",}} /></p>}
                                    {!loading ? (
                                    pastFaceSearches?.length > 0 && (
                                        <div id='pastFacesDiv' style={{justifyContent: pastFaceSearches?.length==1 && 'center'}} ref={scrollContainerRef}>
                                            {pastFaceSearches.map((ele, index) => (
                                                <p key={index} style={{margin: `10px ${pastMargin}px`}} className={pastFaceSearches.length == 1 ? "singleFacesPara" : "pastFacesPara"} onClick={() => indiFaceData(ele._id)}>
                                                    <img
                                                    style={{borderRadius:"50%", height: currentDimensions <600 ? "80px" : "100px"}}
                                                    src={croppedImages[index]}
                                                    alt=""
                                                    />
                                                </p>
                                            ))}
                                        </div>
                                    )
                                    ) : (
                                    <div style={{ paddingTop: "10px" }}>{globalLoader('lg')}</div>
                                    )}
                                    {isScrollable && <p onClick={scrollRight}><FontAwesomeIcon icon={faAngleRight} size="lg" style={{color: "#000000",}} /></p>}
                                    </div>
                                    <div style={{padding:"30px 50px"}}>------or------</div>
                                    <div><button style={{background:get_platform_action_color(), color:"white", borderRadius:"5px", padding:"5px 12px"}} onClick={()=>faceSearch()}>Take a selfie</button></div>
                                </div>
                            </div>
                        </div>}
                    {<div style={{display: !shareLinkPopup && 'none'}}><Popup 
                        isOpen={true}
                        onClose={() => {
                        setShareLinkPopup(false);
                        }}>
                        <div style={{ textAlign: "center", color:"black" }} className="popupWidth">
                            <div style={{ fontSize: "large", paddingTop: "0.2rem", fontWeight:"900" }}>Share</div> 
                            <div id="shareNote"></div>    
                            <div onClick={()=>copyLinkUrl(shareLinkData?.share_url)} className="cpyAlbum">
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{ color: "#ffffff" }}
                                />
                            Copy Link
                            </div>
                            <Link style={{display:"grid", justifyItems:"center", width:"fit-content", margin:"30px auto"}} target='_blank' to={`${getWhatsappShareUrl(shareLinkData?.share_msg)}`}>
                            <button className="shareWp">
                                <FontAwesomeIcon
                                icon={faWhatsapp}
                                style={{ color: "#ffffff" }}
                                />
                                Share
                            </button>
                            </Link>    
                        </div>
                    </Popup></div>}
                    {downloadPopup && <Popup
                        isOpen={true}
                        onClose={() => {
                        setDownloadPopup(false);
                    }}>
                        <div style={{textAlign:"center", fontWeight:"900", fontSize:"25px", minWidth:"20vw"}}>Download</div>
                        <div style={{justifyItems:"center", width:"100%", display:"grid"}} onClick={()=>{setDownloadPopup(false);getSelectionData(download)}}><button style={{background: get_platform_bg_color()}} className="downMethod"><FontAwesomeIcon icon={hollowCheck} style={{paddingRight:"5px", color:"#ffffff"}}/><p>Selectively</p></button></div>
                        <div style={{justifyItems:"center", width:"100%", display:"grid"}} onClick={()=>{setDownloadPopup(false);if(!faceSearchOn){downloadCollection()}else{downloadFaceSearch()}}}><button style={{background: get_platform_action_color()}} className="downMethod"><img className="invert-image" style={{height: "20px", paddingRight:"5px"}} src={downloadIcon} alt="download"/><p>{faceSearchOn ? <span>All Photos</span>  : collection?.title}</p></button></div>
                    </Popup>}
                    {qualityVideo && <Popup
                        isOpen={true}
                        onClose={() => {
                        setQualityVideo(false);
                    }}>
                        <div style={{margin:"0 20px", justifyItems:"center", display:"grid"}}>
                            <div style={{textAlign:"center", fontWeight:"600", fontSize:"23px", padding:"5px"}}>Quality</div>
                            {videoDetails?.map((e) => {
                                return <div key={e.quality} className="qualityRadio">
                                    <label style={{display:"flex", alignItems:"center", justifyContent:"space-between", minWidth:"170px"}}>
                                        <p>{e.name}</p>
                                        <input
                                        type="radio"
                                        name="video"
                                        value={e.quality}
                                        checked={e.quality==currentQuality}
                                        onChange={() => {setCurrentQualityUrl(e.url); setCurrentQuality(e.quality)}}
                                        style={{accentColor:get_platform_action_color()}}
                                        />
                                    </label>
                                </div>;
                            })}
                            <div style={{padding:"5px 10px", borderRadius:"5px", color:"white", background: get_platform_action_color(), width:"fit-content", margin:"5px", cursor:"pointer"}} onClick={downloadVideo}>Download</div>
                        </div>
                    </Popup>}
                    {shareSelective && <div className="shareOverlay">
                        <div className={`shareSelectiveDiv ${currentDimensions<600 ? 'drawer open' : ""}`}>
                            <div style={{fontSize:"larger", fontWeight:"900", width:"100%", display:"flex", justifyContent:"space-between", padding:"10px 5%"}}>
                                <div style={{width:"16px"}}></div>
                                <div>Share Selectively</div>
                                <div><FontAwesomeIcon icon={faXmark} size="lg" onClick={confirShareLost} style={{cursor:"pointer"}}/></div>
                            </div>
                            <div style={{padding:"10px", display:"flex", justifyContent:"space-evenly", width:"100%", color:"white", background: get_platform_bg_color()}}>
                            {data?.data && Object.keys(data?.data).map((key, index) => (
                                <React.Fragment key={index}>
                                    {key === 'photo-collection' && <div key={index}>
                                            <div onClick={()=>switchShareTabs(photos)} style={{cursor:"pointer", display:"flex", alignItems:"center",minWidth:"max-content", padding:"10px 0", margin: currentDimensions > 600 ? "0 20px" : "0 5px" , borderBottom: shareTab==photos && '3px solid white', fontSize: currentDimensions<600 && '13px'}}>
                                                <div><img style={{height:"20px", paddingRight:"5px"}} className="invert-image" src={imageIcon}/></div>
                                                <div><span style={{paddingRight:"5px"}}>Photos</span></div>
                                                {shareSelectList[photos]?.length>0 && <div>{`(${shareSelectList[photos]?.length})`}</div>}
                                            </div>
                                        </div>}  
                                    {key === 'video' && <div key={index}>
                                            <div onClick={()=>switchShareTabs(videos)} style={{cursor:"pointer", display:"flex", alignItems:"center",minWidth:"max-content", padding:"10px 0", margin: currentDimensions > 600 ? "0 20px" : "0 5px" , borderBottom: shareTab==videos && '3px solid white', fontSize: currentDimensions<600 && '13px'}}>
                                                <div><img style={{height:"20px", paddingRight:"5px"}} className="invert-image" src={videoIcon}/></div>
                                                <div><span style={{paddingRight:"5px"}}>Videos</span></div>
                                                {shareSelectList[videos]?.length>0 && <div>{`(${shareSelectList[videos]?.length})`}</div>}
                                            </div>
                                        </div>}
                                    {key === 'album' && <div key={index}>
                                            <div onClick={()=>switchShareTabs(albums)} style={{cursor:"pointer", display:"flex", alignItems:"center",minWidth:"max-content", padding:"10px 0", margin: currentDimensions > 600 ? "0 20px" : "0 5px" , borderBottom: shareTab==albums && '3px solid white', fontSize: currentDimensions<600 && '13px'}}>
                                                <div><img style={{height:"20px", paddingRight:"5px"}} className="invert-image" src={albumIcon}/></div>
                                                <div><span style={{paddingRight:"5px"}}>Albums</span></div>
                                                {shareSelectList[albums]?.length > 0 && <div>{`(${shareSelectList[albums]?.length})`}</div>}
                                            </div>
                                        </div>}
                                </React.Fragment>
                            ))}
                            </div>
                            <div style={{textAlign:"right", padding:"0 24px", width:currentDimensions>600 ? "100%" : "90%", display:"flex", justifyContent:"space-between", padding: "10px 5%"}}>
                                {shareTab==photos && <div>{checkPlurality(getShareTab(shareTab),'Collection')}</div>}
                                {shareTab==videos && <div>{checkPlurality(getShareTab(shareTab),'Video')}</div>}
                                {shareTab==albums && <div>{checkPlurality(getShareTab(shareTab),'Album')}</div>}
                                {getShareTab(shareTab).length == shareSelectList[shareTab]?.length
                                    ? <div style={{display:"flex", alignItems:"center"}} onClick={()=>shareSelectAllCollection(shareTab, [])}><FontAwesomeIcon size="lg" icon={faCircleCheck} style={{color: get_platform_action_color(), backgroundColor:"white", borderRadius:"50%", paddingRight:"5px"}} /><p>Select all</p></div>
                                    : <div style={{display:"flex", alignItems:"center"}} onClick={()=>shareSelectAllCollection(shareTab, getShareTab(shareTab))}><FontAwesomeIcon size="lg" icon={hollowCheck} style={{color: get_platform_action_color(), backgroundColor:"white", borderRadius:"50%", paddingRight:"5px"}} /><p>Select all</p></div>
                                }
                            </div>
                            <div className="shareGrid">{getShareTab(shareTab).map((each) => (
                                <div onClick={()=>shareSelectMedia(shareTab, each.work_id)} style={{margin:"10px", position:"relative", maxWidth:currentDimensions>600 ? "200px" : "150px", minWidth:currentDimensions>600 ? "200px" : "150px", justifyItems:"center", display:"grid"}} key={each.work_id}>
                                    <div className="shareCards">
                                        <img style={{borderRadius:"10px", height:"100%", width:"100%", objectFit:"cover"}} src={shareTab==videos ? each.thumb_url : each.cover_image}/>
                                        <div style={{position:"absolute", width:"100%", height:"100%", background:"rgba(0,0,0,0.5)", top:"0", borderRadius:"10px"}}></div>
                                    </div>
                                    <div style={{padding:"5px 0"}}>{each.title}</div>
                                    <div style={{position:"absolute", top:"10px", left:"20px", cursor:"pointer"}}>
                                        <div>{shareSelectList && shareSelectList[shareTab]?.includes((each.work_id).toString()) 
                                            ? <FontAwesomeIcon size='lg' icon={faCircleCheck} style={{color: get_platform_action_color(), backgroundColor:"white", borderRadius:"50%"}} />
                                            : <FontAwesomeIcon size='lg' icon={hollowCheck} style={{color: get_platform_action_color(), backgroundColor:"white", borderRadius:"50%"}} />}
                                        </div>
                                    </div>
                                </div>
                            ))}</div>
                            <div style={{textAlign:"right", padding:"15px 24px"}}>
                                <button onClick={()=>{!isAdmin() && shareSelection()}} className="shareSelectiveDone" style={{background: get_platform_action_color()}}>{`Share(${Object.values(shareSelectList).flat().length})`}</button>
                            </div>
                        </div>
                    </div>}
                </>
             : <>
                {faceOnly ? <>
                    <Navbar location={"/workDetails"} title={``}/>
                    <div>
                        <FaceSearch updateFaceSearchResult={updateFaceSearchResult}/>
                        {pastFaceSearches?.length > 0 && <p style={{padding:"15px 0"}}>Past face searches!</p>}
                        <div style={{display:"flex", alignItems:"center", width:"100%", justifyContent:"center"}}>
                            <div style={{display:"flex", justifyContent:"center", width:"100%", alignItems:"center"}}>
                                {isScrollable && <p onClick={scrollLeft}><FontAwesomeIcon icon={faAngleLeft} size="lg" style={{color: "#000000",}} /></p>}
                                {Array.isArray(pastFaceSearches) ? (
                                pastFaceSearches?.length > 0 && (
                                    <div id='pastFacesDiv'  style={{maxWidth: currentDimensions > 600 ? "60%" : "90%", width:"auto", justifyContent: pastFaceSearches?.length==1 && 'center'}} ref={scrollContainerRef}>
                                    {pastFaceSearches.map((ele, index) => (
                                    <p key={index} style={{margin: `10px ${pastMargin}`}} className="pastFacesPara" onClick={() => indiFaceData(ele._id)}>
                                        <img
                                        style={{borderRadius:"50%", height: currentDimensions <600 ? "80px" : "100px"}}
                                        src={croppedImages[index]}
                                        />
                                    </p>
                                    ))}
                                    </div>
                                )
                                ) : (
                                <div style={{ paddingTop: "30px" }}>{globalLoader('lg')}</div>
                                )}
                                {isScrollable && <p onClick={scrollRight}><FontAwesomeIcon icon={faAngleRight} size="lg" style={{color: "#000000",}} /></p>}
                            </div>
                        </div>
                    </div>
                </>  : <SkeletonLoader />}
            </>}
        </>
    )
}

export default WorkDetail
