import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./CompUpload.css";
import { useStateContext } from "../../context/StateContext";
import StoreToken from "../../components/StoreToken";
import { getAuthToken, isAdmin, globalLoader } from "../../script/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowUp,
  faClock,
  faCircleCheck,
  faXmark,
  faRotateRight,
  faDownload,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";
import { useLocation, Link } from "react-router-dom";
import Masonry from "react-masonry-css";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const CompUpload = () => {
  const [selectedFile, setSelectedFile] = useState({});
  const [awsData, setAwsData] = useState();
  const [basicData, setBasicData] = useState();
  const [unsupportFile, setUnSupportFile] = useState(false);
  const thumbState = useRef(false);
  const [allPhotos, setAllPhotos] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [numberImages, setNumberImages] = useState(0);
  const [bid, setBid] = useState();
  const [popup, setPopup] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [indexDisplay, setIndexDisplay] = useState(1);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [yourPhotos, setYourPhotos] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const { isLoggedin } = useStateContext();
  const { storeToLocal } = StoreToken();
  const uploadRef = useRef();
  const index = useRef(0);
  const toBeUploaded = useRef([]);
  const ignoreDuplicate = useRef([]);
  const thumbRef = useRef();
  const duplicacy = useRef([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  useEffect(() => {
    setAwsData(JSON.parse(localStorage.getItem("awsData")));
  }, []);
  const breakpointColumnsObj = {
    default: 4,
    1300: 4,
    800: 3,
    600: 2,
  };

  const validData = (event) => {
    const supported = [...event.target.files];
    console.log(supported);
    var updated;
    supported.map((e, index) => {
      updated = supported.filter((e) => e.type.split("/")[0] === "image");
      console.log(updated);
      setUnSupportFile(true);
      setTimeout(() => {
        setUnSupportFile(false);
      }, 3000);
    });
    console.log(supported);
    console.log(updated);
    // toBeUploaded.current = [...event.target.files];
    toBeUploaded.current = updated;
    // const files = [...event.target.files];
    const files = updated;
    Array.from(files).map((e) => {
      if (Object.keys(duplicacy.current).length === 0) {
        setSelectedFile((prevState) => ({
          ...prevState,
          [e.name]: {
            file: e,
            flag: false,
          },
        }));
      } else if (
        Object.keys(duplicacy.current).length > 0 &&
        duplicacy.current.hasOwnProperty(e.name)
      ) {
        setSelectedFile((prevState) => ({
          ...prevState,
          [e.name]: {
            file: e,
            flag: true,
          },
        }));
      } else if (
        Object.keys(duplicacy.current).length > 0 &&
        !duplicacy.current.hasOwnProperty(e.name)
      ) {
        setSelectedFile((prevState) => ({
          ...prevState,
          [e.name]: {
            file: e,
            flag: false,
          },
        }));
      }
    });
    toBeUploaded.current.length > 0 && setPopup(true);
  };
  useLayoutEffect(() => {
    storeToLocal();
  }, []);

  const handleIndexChange = (e) => {
    let num = parseInt(e.curIndexForDisplay, 10);
    setIndexDisplay(num);
    setBid(num);
  };
  useEffect(() => {
    if (thumbRef.current) {
      var widthThumb = thumbRef.current.clientWidth;
      if (widthThumb > 600) {
        thumbState.current = true;
      }
    }
  }, [thumbRef.current]);
  const getUrl = (event) => {
    const authToken = getAuthToken();
    const headers = {
      Authorization: authToken,
    };
    axios
      .get(`${process.env.REACT_APP_STAG_URL}/api/work/guest/upload/${id}/`, {
        headers,
      })
      .then((response) => {
        localStorage.setItem("awsData", JSON.stringify(response.data.data));
        setAwsData(JSON.parse(localStorage.getItem("awsData")));
        validData(event);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const basicDetails = () => {
    const authToken = getAuthToken();
    const headers = {
      Authorization: authToken,
    };
    axios
      .get(`${process.env.REACT_APP_STAG_URL}/api/booking/${id}/meta/`, {
        headers,
      })
      .then((response) => {
        setBasicData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    basicDetails();
  }, []);
  const getPhotos = () => {
    setLoading(true);
    setLoaded(false);
    const authToken = getAuthToken();
    const headers = {
      Authorization: authToken,
    };
    axios
      .get(`${process.env.REACT_APP_STAG_URL}/api/work/guest/gallery/${id}/`, {
        headers,
      })
      .then((response) => {
        setLoading(false);
        setUploadedImages([...response.data.data.Contents]);
        const requestingId = response.data.data.requesting_user_id;
        [...response.data.data.Contents].map((e, index) => {
          if (requestingId === e.user_id) {
            setYourPhotos((prevState) => ({
              ...prevState,
              [index]: e,
            }));
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    getPhotos();
  }, []);
  useEffect(() => {
    if (location.hash) {
      const match = location.hash;
      match === "#gallery" && setShowGallery(true);
      const element = document.getElementsByClassName("photoGrid");
      if (element.length > 0) element[0].style.display = "none";
    } else if (document.getElementsByClassName("photoGrid").length > 0) {
      setShowGallery(false);
      const element = document.getElementsByClassName("photoGrid");
      element[0].style.display = "flex";
    }
  }, [location]);

  const handleFileChange = async (event) => {
    if (!awsData) {
      getUrl(event);
    } else if (awsData && awsData.expires_at <= Date.now()) {
      getUrl(event);
    } else {
      validData(event);
    }
  };

  if (Object.keys(selectedFile).length > 0)
    duplicacy.current = { ...selectedFile };
  popup
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "");
  const compressionUpload = async (imageFile) => {
    const lastModified_meta = new Date(imageFile.lastModified).toISOString();
    const date = new Date(imageFile.lastModified);
    const lastModified = date.getTime();
    const newFileName = `${lastModified}.${imageFile.name}`;
    const uniqueName = new File([imageFile], newFileName, {
      type: "image/jpeg",
    });
    const appendName = uniqueName;
    if (
      ignoreDuplicate.current.includes(appendName.name) &&
      duplicacy.current[imageFile.name].flag
    ) {
      const ele = document.getElementsByClassName(`${imageFile.name}`);
      ele[0].style.display = "none";
      ele[1].style.display = "none";
      ele[2].style.display = "block";
      ele[3].style.display = "none";
      ele[4].style.display = "none";
      setCurrent((prevState) => prevState + 1);
      const updateArray = { ...selectedFile };
      delete updateArray[imageFile.name];
      setSelectedFile(updateArray);
      index.current = index.current + 1;
      setCurrent((prevState) => prevState - 1);
    } else {
      ignoreDuplicate.current.push(appendName.name);
      const elements = document.getElementsByClassName(`${imageFile.name}`);
      elements[0].style.display = "none";
      elements[1].style.display = "block";
      elements[2].style.display = "none";
      elements[3].style.display = "none";
      elements[4].style.display = "none";
      if (navigator.onLine) {
        const options = {
          maxSizeMB: 1,
          useWebWorker: true,
        };
        var convert = appendName;
        setCurrent((prevState) => prevState + 1);
        index.current = index.current + 1;
        try {
          if (
            imageFile.type === "image/heif" ||
            imageFile.type === "image/heic"
          ) {
            const result = await heic2any({ blob: appendName });
            const convertedFile = new File(
              [result],
              appendName.name.toLowerCase().replace(/\.heic$/, ".jpg"),
              {
                type: "image/jpeg",
              }
            );
            const converted = new File(
              [convertedFile],
              appendName.name.toLowerCase().replace(/\.heif$/, ".jpg"),
              {
                type: "image/jpeg",
              }
            );
            convert = converted;
          }
          const preCompressedFile = await imageCompression(convert, options);
          const compressedFile = new File(
            [preCompressedFile],
            preCompressedFile.name.replace(/\.heic$/, ".jpg"),
            {
              type: "image/jpeg",
            }
          );
          try {
            if (imageFile === null) {
              return console.error("Please Upload image");
            }
            const data = new FormData();
            data.append(
              "Content-Disposition",
              awsData.fields["Content-Disposition"]
            );
            data.append(
              "x-amz-storage-class",
              awsData.fields["x-amz-storage-class"]
            );
            data.append("key", awsData.fields["key"]);
            data.append("AWSAccessKeyId", awsData.fields["AWSAccessKeyId"]);
            data.append("policy", awsData.fields["policy"]);
            data.append("signature", awsData.fields["signature"]);
            data.append("Content-Type", awsData.fields["Content-Type"]);
            data.append("x-amz-meta-created-timestamp", `${lastModified_meta}`);
            data.append("file", compressedFile);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: awsData.url,
              headers: {
                "Content-Type": "image/jpeg",
              },
              data: data,
            };

            axios
              .request(config)
              .then((response) => {
                setCurrent((prevState) => prevState - 1);
                const elements2 = document.getElementsByClassName(
                  `${imageFile.name}`
                );
                elements2[0].style.display = "none";
                elements2[1].style.display = "none";
                elements2[2].style.display = "block";
                elements2[3].style.display = "none";
                elements2[4].style.display = "none";
                const updateflag = { ...duplicacy.current };
                updateflag[imageFile.name].flag = true;
                duplicacy.current = updateflag;
              })
              .catch((error) => {
                console.log(error);
                setCurrent((prevState) => prevState - 1);
                const elements3 = document.getElementsByClassName(
                  `${imageFile.name}`
                );
                elements3[0].style.display = "none";
                elements3[1].style.display = "none";
                elements3[2].style.display = "none";
                elements3[3].style.display = "block";
                elements3[4].style.display = "block";
              });

            // while (lock.current) {}
            // lockAcess();
          } catch (error) {
            console.log(error);
          }
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      } else {
        elements[0].style.display = "none";
        elements[1].style.display = "none";
        elements[2].style.display = "none";
        elements[3].style.display = "block";
        elements[4].style.display = "block";
      }
    }
  };
  const handleLoading = () => {
    setNumberImages((prev) => prev + 1);
    if (allPhotos && numberImages > uploadedImages.length - 10) {
      setLoaded(true);
    } else if (
      !allPhotos &&
      numberImages > Object.keys(yourPhotos).length - 10
    ) {
      setLoaded(true);
      setNumberImages(0);
    }
  };
  const closeGalleryHandler = () => {
    setShowGallery(false);
    const element = document.getElementsByClassName("photoGrid");
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname + window.location.search
    );
    element[0].style.display = "block";
    const element_scroll = document.getElementById(`gallery${indexDisplay}`);
    element_scroll.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    console.log(current, Object.keys(selectedFile).length, index.current);
    if (
      current < 5 &&
      Object.keys(selectedFile).length > 0 &&
      index.current < toBeUploaded.current.length
    ) {
      compressionUpload(toBeUploaded.current[index.current]);
    }
  }, [selectedFile, current]);
  const retry = (event) => {
    for (let i = 0; i < Object.keys(selectedFile).length; i++) {
      if (toBeUploaded.current[i].name === event.currentTarget.className) {
        compressionUpload(toBeUploaded.current[i]);
      }
    }
  };

  const galleryHandler = (event) => {
    setBid(parseInt(event.currentTarget.id, 10));
    setIndexDisplay(event.currentTarget.id);
    setShowGallery(true);
    const element = document.getElementsByClassName("photoGrid");
    element[0].style.display = "none";
  };
  const play = document.getElementsByClassName("_ZTBlf");
  if (play[0]) play[0].style.marginLeft = "50px";
  const fullSize = document.getElementsByClassName("_lfOsC _dZ8C-");
  if (fullSize[1]) {
    fullSize[1].addEventListener("click", () => {
      const elem = thumbRef.current;
      if (!document.fullscreenElement) {
        setFullScreen(true);
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        }
      } else {
        setFullScreen(false);
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    });
  }
  const images = uploadedImages.map((image) => ({
    src: `${image.url}`,
    alt: `${image.user_full_name}`,
    thumbnail: `${image.url}`,
  }));

  const your_images = Object.keys(yourPhotos).map((e) => ({
    src: `${yourPhotos[e].url}`,
    alt: `${yourPhotos[e].user_full_name}`,
    thumbnail: `${yourPhotos[e].url}`,
  }));

  const downloadImage = () => {
    var link = document.createElement("a");
    link.href = allPhotos
      ? images[indexDisplay - 1].src
      : your_images[indexDisplay - 1].src;
    link.download = "Download.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      {isLoggedin && basicData ? (
        <>
          <Navbar
            location={"/compupload"}
            title={`${basicData.booking_name}`}
          />
          {basicData.guest_gallery_meta.is_active ? (
            <>
              <div
                style={{
                  position: "fixed",
                  zIndex: "100",
                  top: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  height: "100vh",
                  width: "100vw",
                  backgroundColor: "rgba(0,0,0,0.7)",
                  display: popup ? "flex" : "none",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px 20px",
                    display: "inline",
                    maxHeight: "80vh",
                    overflowY: "scroll",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      padding: "5px 0",
                      fontSize: "1.2rem",
                      fontWeight: "800",
                      position: "relative",
                    }}
                  >
                    {current !== 0 ? "Uploading..." : "Uploaded"}
                    <span
                      style={{ position: "absolute", right: "0" }}
                      onClick={
                        current !== 0
                          ? isDisabled
                          : () => {
                              setNumberImages(0);
                              setPopup(false);
                              getPhotos();
                              setSelectedFile([]);
                              index.current = 0;
                            }
                      }
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </span>
                  </div>
                  {toBeUploaded.current.length > 0 &&
                    toBeUploaded.current.map((e, index) => (
                      <>
                        <div
                          style={{
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          key={index}
                        >
                          <span className={e.name}>
                            <FontAwesomeIcon
                              icon={faClock}
                              style={{ color: "#000000" }}
                            />
                          </span>
                          <span style={{ display: "none" }} className={e.name}>
                            {globalLoader('xs')}{" "}
                          </span>
                          <span style={{ padding: "0 10px" }}> {e.name}</span>
                          <span className={e.name} style={{ display: "none" }}>
                            {" "}
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              style={{ color: "#000000" }}
                            />
                          </span>
                          <span className={e.name} style={{ display: "none" }}>
                            {" "}
                            <FontAwesomeIcon
                              icon={faCircleXmark}
                              style={{ color: "#ff0000" }}
                            />
                          </span>
                          <span
                            className={e.name}
                            onClick={retry}
                            style={{ display: "none", paddingLeft: "10px" }}
                          >
                            {" "}
                            <FontAwesomeIcon icon={faRotateRight} />
                          </span>
                        </div>
                        <hr></hr>
                      </>
                    ))}
                </div>
              </div>
              {showGallery && bid >= 0 && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: "200",
                    top: "0",
                    left: "0",
                    height: "100vh",
                    width: "100vw",
                  }}
                >
                  <Carousel
                    index={bid - 1}
                    images={allPhotos ? images : your_images}
                    hasCaptions="bottom"
                    onIndexChange={handleIndexChange}
                    hasThumbnails={thumbState.current}
                  />
                  <div
                    style={{ position: "absolute", top: "11px", right: "60px" }}
                    onClick={downloadImage}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      className="downloadEffect"
                      size="xl"
                    />
                  </div>
                  <div
                    style={{ position: "absolute", top: "11px", left: "20px" }}
                    onClick={closeGalleryHandler}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      size="xl"
                      className="closeEffect"
                    />
                  </div>
                </div>
              )}
              <div className="uploadDiv">
                <div>
                  <input
                    style={{ display: "none" }}
                    ref={uploadRef}
                    accept=".heic, .heif, .jpeg, .jpg"
                    type="file"
                    onChange={handleFileChange}
                    multiple
                  />
                  {basicData.guest_gallery_meta.guest_upload_allowed && (
                    <button
                      style={{
                        border: "1px solid black",
                        background: "black",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "8px",
                        fontWeight: "800",
                      }}
                      onClick={() => {
                        !isAdmin() && uploadRef.current.click();
                      }}
                    >
                      Upload{" "}
                      <span
                        style={{
                          verticalAlign: "super",
                          fontSize: "0.7rem",
                          fontWeight: "normal",
                        }}
                      >
                        BETA
                      </span>{" "}
                      <FontAwesomeIcon
                        icon={faCloudArrowUp}
                        size="sm"
                        style={{ color: "#ffffff" }}
                      />
                    </button>
                  )}
                </div>
              </div>
              <div
                style={{
                  display:
                    Object.keys(yourPhotos).length > 0 ? "block" : "none",
                }}
              >
                <button
                  onClick={() => {
                    setAllPhotos(true);
                  }}
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    margin: "0 10px 10px 0",
                    color: allPhotos ? "white" : "black",
                    backgroundColor: allPhotos ? "black" : "white",
                  }}
                >
                  All Photos
                </button>
                <button
                  onClick={() => {
                    setAllPhotos(false);
                  }}
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    margin: "0 0 10px 10px",
                    color: !allPhotos ? "white" : "black",
                    backgroundColor: !allPhotos ? "black" : "white",
                  }}
                >
                  Your Uploads
                </button>
              </div>
              {loading ? (
                <div className="fullpage-overlay">{globalLoader()}</div>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="photoGrid"
                >
                  {uploadedImages.length > 0 && allPhotos ? (
                    <div ref={thumbRef}>
                      <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                      >
                        {uploadedImages.map((e, index) => (
                          <a href={`#gallery`}>
                            <div
                              id={`gallery${index + 1}`}
                              key={index}
                              style={{ position: "relative" }}
                            >
                              <img
                                id={`${index + 1}`}
                                style={{
                                  display: "block",
                                  width: "100%",
                                  padding: "2.5px",
                                }}
                                key={index}
                                src={e.url}
                                onLoad={handleLoading}
                                onClick={galleryHandler}
                              />
                              {e.user_full_name.length < 18 ? (
                                <div
                                  style={{ display: loaded ? "block" : "none" }}
                                  className="photoName"
                                >
                                  {e.user_full_name}
                                </div>
                              ) : (
                                <div
                                  style={{ display: loaded ? "block" : "none" }}
                                  className="photoName"
                                >
                                  {e.user_full_name.substring(0, 18) + "..."}
                                </div>
                              )}
                            </div>
                          </a>
                        ))}
                      </Masonry>
                    </div>
                  ) : (
                    <div>
                      {allPhotos && (
                        <div className="noPhotoDiv">
                          UHH-OH! No Photos
                          <img
                            style={{ borderRadius: "50%" }}
                            className="noPhoto"
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpoFaWmQUqmyDEo3WRo_z4OX3sS9uGEMflcQ&usqp=CAU"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {Object.keys(yourPhotos).length > 0 && !allPhotos && (
                    <div>
                      <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                      >
                        {Object.keys(yourPhotos).map((e, index) => {
                          const obj = yourPhotos[e];
                          return (
                            <a href={`#gallery`}>
                              <div
                                id={`gallery${index + 1}`}
                                key={index}
                                style={{ position: "relative" }}
                              >
                                <img
                                  id={`${index + 1}`}
                                  style={{
                                    display: "block",
                                    width: "100%",
                                    padding: "2.5px",
                                  }}
                                  key={index}
                                  src={obj.url}
                                  onLoad={handleLoading}
                                  onClick={galleryHandler}
                                />
                                {obj.filename.length < 18 ? (
                                  <div className="photoName">
                                    {obj.user_full_name}
                                  </div>
                                ) : (
                                  <div className="photoName">
                                    {obj.user_full_name.substring(0, 18) +
                                      "..."}
                                  </div>
                                )}
                              </div>
                            </a>
                          );
                        })}
                      </Masonry>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div>{basicData.message}</div>
              <Link to="/">
                <button
                  style={{
                    border: "1px solid black",
                    background: "black",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    fontWeight: "800",
                  }}
                >
                  Go to Dashboard
                </button>
              </Link>
            </>
          )}
        </>
      ) : (
        <div className="fullpage-overlay">{globalLoader()}</div>
      )}
      {console.log(unsupportFile)}
      {unsupportFile && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0.7)",
            color: "white",
            zIndex: "100",
            padding: "5px 10px",
            borderRadius: "10px",
            top: "28px",
            right: "20px",
          }}
        >
          <FontAwesomeIcon icon={faCircleInfo} style={{ color: "white" }} />{" "}
          Only images are supported!
        </div>
      )}
    </>
  );
};

export default CompUpload;
