import React from 'react'
import { useNavigate } from 'react-router-dom';
import { GetProfile, getLoginPage, getQueryVariable, isAdmin } from '../script/util';
import { useStateContext } from '../context/StateContext'

const StoreToken = () => {
    const { isLoggedin, setisLoggedin } = useStateContext();
    const navigate = useNavigate();
    const storeToLocal = (quot_page=false) => {
        if (quot_page && getQueryVariable("quot-key")) {
          localStorage.setItem("quot-key", getQueryVariable("quot-key"));
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.delete("quot-key");
          navigate({ search: searchParams.toString() }, { replace: true });
          return
        }
        if (getQueryVariable("token")) {
          localStorage.setItem("eventGraphiaToken", getQueryVariable("token"));
          localStorage.removeItem("quot-key");
          const url = new URL(window.location.href);
          url.searchParams.delete('token');
          window.history.replaceState({}, document.title, url.toString());
        }
        if (localStorage.eventGraphiaToken !== undefined) {
          setisLoggedin(true);
          GetProfile();
        } else if (quot_page && localStorage.getItem("quot-key")) {
          return
        } else {
          setisLoggedin(false);
          getLoginPage();
        }
      };
  return {
    storeToLocal
  }
}

export default StoreToken