import React, { useRef } from 'react'
import './Navbar.css'
import { AiFillHome } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import Sidebar from '../Sidebar/Sidebar'
import { token_url, get_logo } from '../../script/util'
import { useStateContext } from '../../context/StateContext'
import { IoArrowBack } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";


const Navbar = ({ title, location }) => {
  const upload = useRef(false)
  const { isOpen, setisOpen } = useStateContext();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  let left;
  if (location === token_url(`${process.env.REACT_APP_STAG_URL}`)) {
    left = <Link to={location}><AiFillHome /></Link>;
  } else {
    left = <Link to='/'><IoArrowBack /></Link>;
    upload.current = true
  }
  const navigate = useNavigate();
  const handleBackPress = () => {
    navigate(`/?id=${id}`)
  };

  return (
    <>
      <div className="navbar">
        {!upload.current && <div className="nav-left" onClick={handleBackPress}>{left}</div>}
        {upload.current && <div className='nav-left' ><Link to='/'><img className='logoSize' src={get_logo(true)} /></Link></div>}
        {/* <div className="nav-middle">{title}</div> */}
        <div className="nav-right">
          <div className='connectDiv'>
            <p style={{ paddingRight: "20px", cursor: "pointer" }}><a href={`https://www.instagram.com/${process.env.REACT_APP_PLATFORM}/`} target='_blank'><span className='brandIcon'><FontAwesomeIcon icon={faInstagram} size={window.innerWidth < 600 ? 'xl' : "sm"} style={{ color: "#000000", }} /></span><span style={{fontSize: window.innerWidth<1200 && "15px"}} className='navBrandText'> {" "}Follow</span></a></p>
            <p style={{ paddingRight: "20px", cursor: "pointer" }}><a href={token_url(`${process.env.REACT_APP_STAG_URL}/callback`)}><span className='brandIcon'><FontAwesomeIcon icon={faPhone} size={window.innerWidth < 600 ? 'xl' : "sm"} style={{ color: "#000000", }} /></span><span className='navBrandText' style={{fontSize: window.innerWidth<1200 && "15px"}}> {" "}Enquire</span></a></p>
          </div>
          <GiHamburgerMenu style={{ width: "30px" }} onClick={() => setisOpen(true)} />
        </div>
      </div>
      {
        isOpen && <Sidebar />
      }
    </>
  )
}

export default Navbar