import { useState, useEffect, useCallback } from 'react';
import { isTouch } from './util';

export const useImageZipper = () => {
    const [worker, setWorker] = useState(null);
    const [isZipping, setIsZipping] = useState(false);
    const [progress, setProgress] = useState(0);
    const [downloadKey, setDownloadKey] = useState()
    const [error, setError] = useState(null);
    const [zipFilename, setZipFilename] = useState('images.zip');

    useEffect(() => {
        if(worker && downloadKey){
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/service-worker.js')
                .catch(error => {
                    console.error('Service Worker registration failed:', error);
                });
            }
            processNextBatch()
        }
    }, [worker, downloadKey]);

        const createWorker = async () => {
            const response = await fetch('/service-worker.js');
            const workerCode = await response.text();
            const blob = new Blob([workerCode], { type: 'application/javascript' });
            const workerUrl = URL.createObjectURL(blob);
            const webWorker = new Worker(workerUrl);
            setWorker(webWorker);

            return () => {
                webWorker.terminate();
                URL.revokeObjectURL(workerUrl);
            };
        };

        

    const zipImages = useCallback((batchUrls) => {
        if (!worker || !batchUrls.length) return;
        
        setIsZipping(true);
        setProgress(0);
        setError(null);

        return new Promise((resolve, reject) => {
            worker.onmessage = (event) => {
                const { type, url, message, progress } = event.data;
                switch (type) {
                    case 'PROGRESS':
                        setProgress(progress);
                        break;
                    case 'COMPLETE':
                        setIsZipping(false);
                        setProgress(100);
                        resolve(url);
                        break;
                    case 'ERROR':
                        setIsZipping(false);
                        setError(message);
                        reject(new Error(message));
                        break;
                }
            };

            worker.postMessage({
                type: 'START_ZIP',
                urls: batchUrls
            });
        });
    }, [worker]);

    const initializeBatches = (urlData, dataKey) => {
        const storedBatches = JSON.parse(localStorage.getItem(dataKey)) || [];
        const nextBatchIndex = getNextBatchIndex(dataKey);
        if (!(storedBatches.length && nextBatchIndex >= 0)) {
            let batches = [];
            for (let i = 0; i < urlData.length; i++) {
                let batch = {}
                const item = urlData[i];
                const urlList = [];
                for (let j = 0; j < item.url_data.length; j++) {
                  const urlData = item.url_data[j];
                  urlList.push(urlData.download_url);
                }
                batch['urls'] = urlList;
                batch['is_downloaded'] = false;
                batch['batch_name'] = item.batch_name
                batches.push(batch)
              }
            // const batches = createBatches(urlData);

            localStorage.setItem(dataKey, JSON.stringify(batches));
        }
    };

    const getNextBatchIndex = (dataKey) => {
        const storedBatches = JSON.parse(localStorage.getItem(dataKey)) || [];
        return storedBatches.findIndex(batch => !batch.is_downloaded);
    };

    const updateBatchStatus = (batchIndex) => {
        const storedBatches = JSON.parse(localStorage.getItem(downloadKey)) || [];
        if (storedBatches[batchIndex]) {
            storedBatches[batchIndex].is_downloaded = true;
            localStorage.setItem(downloadKey, JSON.stringify(storedBatches));
        }
    };

    const downloadZip = async (urlData, filename = 'images.zip', dataKey) => {
        setDownloadKey(dataKey)
        setZipFilename(filename);
        initializeBatches(urlData, dataKey);
        if (!worker) {
            createWorker();
        }
    };

    const processNextBatch = async () => {
        const nextBatchIndex = getNextBatchIndex(downloadKey);
        const storedBatches = JSON.parse(localStorage.getItem(downloadKey)) || [];


        if (nextBatchIndex >= 0 && storedBatches[nextBatchIndex]) {
            localStorage.setItem('downloadBatch', true);

            try {
                const downloadUrl = await zipImages(storedBatches[nextBatchIndex].urls);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = `${storedBatches[nextBatchIndex].batch_name}.zip`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(downloadUrl);

                updateBatchStatus(nextBatchIndex); // Mark batch as downloaded

                if(!storedBatches[nextBatchIndex + 1]){
                    localStorage.removeItem('downloadBatch');
                    // localStorage.removeItem(downloadKey);
                }
                var delay = isTouch() ? 12000 : 8000
                // Reload to trigger the next batch
                setTimeout(() => {
                    window.location.reload();
                }, delay);
            } catch (err) {
                setError(err.message);
                throw err;
            }
        } else {
            localStorage.removeItem('downloadBatch');
            // localStorage.removeItem(downloadKey);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    };

    return {
        downloadZip,
        getNextBatchIndex,
        isZipping,
        progress,
        error
    };
};
