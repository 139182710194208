// ImageZipper.js
import React, { useEffect } from 'react';
import { useImageZipper } from './UseImageZipper'; // Assuming the hook is in a separate file

export function ImageZipper({ 
    imageUrls = [], 
    zipFilename = 'images.zip', 
    onProgress = () => {},
    triggerZip,
    dataKey
}){
    const { downloadZip, getNextBatchIndex, isZipping, progress, error } = useImageZipper();

    // This is the function that will zip the images
    const handleZip = async () => {
        if (!imageUrls.length) return;
        try {
            await downloadZip(imageUrls, zipFilename, dataKey);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (isZipping) {
            onProgress(getNextBatchIndex(dataKey), progress);
        }
    }, [isZipping, progress, onProgress]);

    // Ensure that handleZip is correctly passed to setTriggerZip as a function
    useEffect(() => {
        triggerZip && handleZip() // Correctly pass handleZip as a function
    }, []);

    return (
        <div></div>
    );
};